import React, { useState } from 'react'
import {Row, Col} from "react-bootstrap";
import GraphService from "../service/GraphService";

const DetailsHistory = ({ intl, code, start, end  }) => {
  let service = new GraphService()
  const [allDetails, setAllDetails] = useState(null)

  const getDetailHistory = () => {
    document.getElementById("observationsDetails").classList.toggle("collapse");
    document.getElementById("observationsDetailsArrow").classList.toggle("icon-rotate");

    let params = {
      start,
      end,
      code, 
      sort: "desc"
    }

    if(!document.getElementById("observationsDetails").classList?.contains("collapse")) {
      service.getDetailHistory(params).then((response) => {    
          if (response !== undefined) {
            setAllDetails(response)
          } else {
            setAllDetails(null);
          }
      }).catch(e => {
        console.log(e);
      });
    } else {
      setAllDetails(null);
    }

  }

  return (      
      <>
      <Row>
          <Col className="text-center">
            <button onClick={getDetailHistory} className="details-button mt-3 mb-2" type="button" data-toggle="collapse" data-target="#observationsDetails" aria-expanded="false" aria-controls="observationsDetails">
            {intl.formatMessage({id: "details"})} <svg xmlns="http://www.w3.org/2000/svg" id="observationsDetailsArrow" width="16" height="16" fill="currentColor" className="icon-rotate bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                      </svg>
            </button>
          </Col>
      </Row>
      <Row>
        <Col>
          <div className="collapse" id="observationsDetails">
              {allDetails && allDetails?.length && allDetails.length > 0 && allDetails?.map( detail => {
                const dateRegistered = detail.effectiveDateTime.substring(0,16).replace("T",", ");
                if(code === "443450003"){
                  return (
                    <div className="observations-details" key={detail?.id}>

                      <div className="observations-details-outer">
                        <div className="observations-details-indvidual is-total-score">
                          <div className="score-inner is-total-score is-score-highlighted">
                            <p><span className="score-value">{detail.value[0].value}</span>/25</p>
                          </div>
                          <div className="observations-details-indvidual is-total-score-text">
                            <h5>
                              {intl.formatMessage({id: "act_total_score"})}
                            </h5>
                            <p>
                              {detail.value[0].value +" "+ intl.formatMessage({id: "act_level_of_25"})}
                            </p>
                          </div>
                        </div>
                        <div className="observations-details-indvidual">
                          <h5>
                            {intl.formatMessage({id: "hr_pain_registered"})}
                          </h5>
                          <p>
                            {dateRegistered}
                          </p>
                        </div>
                      </div>
                      
                      <div className="observations-details-indvidual">
                        <p className="score-result">
                          {detail.value[0].value >= 20 
                            ? intl.formatMessage({id: "act_Result_20+"}) 
                            : intl.formatMessage({id: "act_Result_19-"})}
                        </p>
                      </div>
                      
                      {detail.questionnaires && detail.questionnaires.map(question => {
                        let belowAve = false;
                        if(Math.floor(detail.value[0].value/5) > question.score){
                          belowAve = true;
                        }
                        if(!question.answerCode && !question.answerText){
                          return null
                        }
                        return (
                          <div className="observations-details-indvidual score" key={question.questionCode}>
                            <div className="score-outer">
                              <div>
                                <h5>
                                  {intl.formatMessage({id: question.questionCode})}
                                </h5>
                                <p className={`observation-details-answer${ belowAve ? " is-below" : ""}`}>
                                  {(question.answerText && question.answerText !== "-") 
                                    ? question.answerText 
                                    : intl.formatMessage({id: question.answerCode})}
                                </p>
                              </div>
                              <div className={`score-inner${ belowAve ? " is-score-highlighted" : ""}`}>
                                <p><span className="score-value">{question.score}</span>/5</p>
                              </div>
                            </div>
                          </div> 
                        )
                      })}                           
                    </div>
                  )
                } else if(code === "4869002") {                  
                  return (
                    <div className="observations-details" key={detail?.id}>
                      <div className="observations-details-outer">
                        <div className="observations-details-indvidual is-caisr">
                          <h5>
                            {intl.formatMessage({id: "hr_pain_registered"})}
                          </h5>
                          <p>
                            {dateRegistered}
                          </p>
                        </div>
                      </div>
                      {/* anxiety level */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_level_question"})}
                        </h5>
                        <p>
                          <span className={`is-caisr is-level is-level-${detail.questionnaireList["hr_anxiety_level"]}`}>{detail.questionnaireList["hr_anxiety_level"]}</span>
                        </p>
                      </div>
                      {/* day anxiety */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_day_status"})}
                        </h5>
                        <p>
                          <span className={`is-caisr is-level is-level-${10-detail?.value[0]?.value}`}>{detail.questionnaireList["hr_anxiety_day_status"]}</span>
                        </p>
                      </div>
                      {/* day affect you */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_affect_you"})}
                        </h5>
                        <p>                            
                          {detail.questionnaireList["hr_anxiety_affect_you"] ? intl.formatMessage({id: detail.questionnaireList["hr_anxiety_affect_you"]}) : null}
                        </p>
                      </div>
                      {/* anxiety handling */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_handling"})}
                        </h5>
                        <p>
                          {detail.questionnaireList["hr_anxiety_handling"] ? intl.formatMessage({id: detail.questionnaireList["hr_anxiety_handling"]}) : null}
                        </p>
                      </div>
                      {/* anxiety duration */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_duration"})}
                        </h5>
                        <p>
                          {detail.questionnaireList["hr_anxiety_duration"] ? intl.formatMessage({id: detail.questionnaireList["hr_anxiety_duration"]}) : null}
                        </p>
                      </div>
                      {/* anxiety affect day */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_affect_day"})}
                        </h5>
                        <p>
                          {detail.questionnaireList["hr_anxiety_affect_day"] ? intl.formatMessage({id: detail.questionnaireList["hr_anxiety_affect_day"]}) : null}
                        </p>
                      </div>
                      {/* anxiety affect sleep */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_affect_sleep"})}
                        </h5>
                        <p>
                          {detail.questionnaireList["hr_anxiety_affect_sleep"] ? intl.formatMessage({id: detail.questionnaireList["hr_anxiety_affect_sleep"]}) : null}
                        </p>
                      </div>
                      {/* anxiety cause free text */}
                      <div className="observations-details-indvidual is-caisr">
                        <h5>
                          {intl.formatMessage({id: "hr_anxiety_cause"})}
                        </h5>
                        <p>
                          {detail.questionnaireList["hr_anxiety_cause"] ? detail.questionnaireList["hr_anxiety_cause"] : null}
                        </p>
                      </div>                          
                    </div>
                )
                } else {
                  return (
                    <div className="observations-details" key={detail?.id}>
                      <div className="observations-details-outer">
                        <div className="observations-details-indvidual">
                          <h5>
                            {intl.formatMessage({id: "hr_pain_level"})}
                          </h5>
                          <p>
                            <span className={`is-level is-level-${detail.value[0].value}`}>{detail.value[0].value}</span>
                          </p>
                        </div>
                        <div className="observations-details-indvidual">
                          <h5>
                            {intl.formatMessage({id: "hr_pain_registered"})}
                          </h5>
                          <p>
                            {dateRegistered}
                          </p>
                        </div>
                      </div>
                      {detail.questionnaires && detail.questionnaires.map(question => {
                        if(!question.answerCode && !question.answerText){
                          return null
                        }
                        return (
                          <div className="observations-details-indvidual" key={question.questionCode}>
                            <h5>
                              {intl.formatMessage({id: question.questionCode})}
                            </h5>
                            <p className="observation-details-answer">
                              {(question.answerText && question.answerText !== "-") ? question.answerText : intl.formatMessage({id: question.answerCode})}
                            </p>
                          </div> 
                        )
                      })}                           
                    </div>
                )

                } 
              })}

          </div>
        </Col>
      </Row>
    </>
  )
}

export default DetailsHistory
