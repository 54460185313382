import ApiService from "./ApiService";

export default class MyStatsService {  
    getBaseObservations = async(codes) => {
        return this.apiCall({
            method: "get",
            apiMethod: "observations/latest",
            data: null,
            params: {code: codes}
        });
    }
    
    getObservations = async() => {
        return this.apiCall({
            method: "get",
            apiMethod: "observations/latest/all",
            data: null
        });
    }

    getStatistics = async() => {
        return this.apiCall({
            method: "get",
            apiMethod: "patient/statistics",
            data: null,
            params: null
        });
    }

    getPatient = async() => {
        return this.apiCall({
            method: "get",
            apiMethod: "patient/self",
            data: null,
            params: null
        })
    }

    createReport = async(report, carePlan) => {
        return this.apiCall({
            method: "post",
            apiMethod: `dashboard/patient/${carePlan}`,
            data: report,
            params: null,
            responseType: 'blob'
        })
    }

    getPatientCarePlans = async() => {
        return this.apiCall({
            method: "get",
            apiMethod: "careplan",
            data: null,
            params: null
        })
    }

    getSubscriptions = async () => {
        return this.apiCall({
            method: "get",
            apiMethod: `withings/getsubscriptions`,
            isTokenrequired: true,
            data: null
        });
    }

    addCarePlan = async(carePlan) => {
        return this.apiCall({
            method: "post",
            apiMethod: "careplan",
            data: {
                carePlanName: carePlan
            },
            params: null
        })
    }

    apiCall = async (options) => {
        const service = new ApiService();
        const response = await service.apiCall(options);
        if(response.data !== null && response.data !== undefined) {
            return response.data;
        }
        return null;
    }
}
