import React from "react";
import { injectIntl } from "react-intl";
import {withOktaAuth} from "@okta/okta-react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {setPageInformationAction} from "../redux/actions";
import moment from "moment/moment";
import StatisticsService from "../service/StatisticsService";

class Observations extends React.Component {
    statisticsService = new StatisticsService();
    intl = this.props.intl;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    };

    componentDidMount() {
        this.getAllObservations();
    };

    convertToCsv(data) {
        const rows = data.split('\n');
        const result = [];
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i].split(',');
          result.push(row);
        }
        return result;
      };
    
    getAllObservations = () => {
        this.statisticsService.getAllObservations()
        .then(response => {
            this.setState({ loading: false })
            const downloadTime = moment().format(this.intl.formatMessage({ id: "language_date_format" })+"-HH:mm:ss");
            const csvData = this.convertToCsv(response);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
            link.download = this.intl.formatMessage({ id: "page_observations" })+downloadTime+'.csv';
            link.click();
        })
    };
    page_observations_data_loaded
    render = () => {
        return (
            <>
                <h2>{this.intl.formatMessage({ id: "page_observations" })}</h2>
                {this.state.loading ? <div className="loading-spinner"></div> : <p>{this.intl.formatMessage({ id: "page_observations_data_loaded" })}</p>}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
    };
}

export default connect(null, mapDispatchToProps)(withOktaAuth(withRouter(injectIntl(Observations))));