import React from "react";
import { CallClient, LocalVideoStream, Features } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import {setPageInformationAction} from "../../redux/actions";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import { DefaultButton } from '@fluentui/react/lib/Button';
import CallCard from '../Meeting/CallCard'
import MeetingUserInfo from './MeetingUserInfo';
import { setLogLevel, AzureLogger } from '@azure/logger';
import routes, {ROUTE_TITLES} from "../../routes/routes";

class Meeting extends React.Component {
    intl = this.props.intl;

    constructor(props) {
        super(props);
        this.callClient = null;
        this.environmentInfo = null;
        this.callAgent = null;
        this.deviceManager = null;
        this.destinationUserIds = null;
        this.destinationPhoneIds = null;
        this.destinationGroup = null;
        this.meetingLink = null;
        this.meetingId = null;
        this.threadId = null;
        this.messageId = null;
        this.organizerId = null;
        this.tenantId = null;
        this.callError = null;
        this.logBuffer = [];
        this.meetingInfo = sessionStorage.getItem('meetinginfo') ? JSON.parse(sessionStorage.getItem('meetinginfo')) : null;

        this.state = {
            id: undefined,
            loggedIn: false,
            call: undefined,
            showCallSampleCode: false,
            showEnvironmentInfoCode: false,
            showMuteUnmuteSampleCode: false,
            showHoldUnholdCallSampleCode: false,
            selectedCameraDeviceId: null,
            selectedSpeakerDeviceId: null,
            selectedMicrophoneDeviceId: null,
            deviceManagerWarning: null,
            callError: null,
            ufdMessages: [],
            permissions: {
                audio: null,
                video: null
            }
        };

        setInterval(() => {
            if (this.state.ufdMessages.length > 0) {
                this.setState({ ufdMessages: this.state.ufdMessages.slice(1) });
            }
        }, 10000);
    }

    componentDidMount() {
        localStorage.setItem("pageHeader", ROUTE_TITLES[routes.meeting])
    }

    handleLogIn = async (userDetails) => {
        if (userDetails) {
            try {
                const tokenCredential = new AzureCommunicationTokenCredential(userDetails.token);
                //setLogLevel('verbose');
                this.callClient = new CallClient({ diagnostics: { appName: 'azure-communication-services', appVersion: '1.3.1-beta.1', tags: ["javascript_calling_sdk", `#clientTag:${userDetails.clientTag}`] } });
                this.environmentInfo = await this.callClient.feature(Features.DebugInfo).getEnvironmentInfo();

                this.callAgent = await this.callClient.createCallAgent(tokenCredential, { displayName: userDetails.displayName });

                window.callAgent = this.callAgent;
                this.deviceManager = await this.callClient.getDeviceManager();
                const permissions = await this.deviceManager.askDevicePermission({ audio: true, video: true });
                this.setState({permissions: permissions});

                this.callAgent.on('callsUpdated', e => {
                    console.log(`callsUpdated, added=${e.added}, removed=${e.removed}`);

                    e.added.forEach(call => {
                        this.setState({ call: call });

                        const diagnosticChangedListener = (diagnosticInfo) => {
                            const rmsg = `UFD Diagnostic changed:
                            Diagnostic: ${diagnosticInfo.diagnostic}
                            Value: ${diagnosticInfo.value}
                            Value type: ${diagnosticInfo.valueType}`;
                            if (this.state.ufdMessages.length > 0) {
                                this.setState({ ufdMessages: [...this.state.ufdMessages, rmsg] });
                            } else {
                                this.setState({ ufdMessages: [rmsg] });
                            }


                        };

                        call.feature(Features.UserFacingDiagnostics).media.on('diagnosticChanged', diagnosticChangedListener);
                        call.feature(Features.UserFacingDiagnostics).network.on('diagnosticChanged', diagnosticChangedListener);
                    });

                    e.removed.forEach(call => {
                        if (this.state.call && this.state.call === call) {
                            this.displayCallEndReason(this.state.call.callEndReason);
                        }
                    });
                });
                this.setState({ loggedIn: true });
            } catch (e) {
                console.error(e);
            }
        }
    }

    onHangUp = () => {
        console.log('hanging up')
        window.location.reload()
    }

    displayCallEndReason = (callEndReason) => {
        if (callEndReason.code !== 0 || callEndReason.subCode !== 0) {
            this.setState({ callError: `Call end reason: code: ${callEndReason.code}, subcode: ${callEndReason.subCode}` });
        }

        this.setState({ call: null });
    }
    
    joinTeamsMeeting = async (withVideo) => {
        try {
            const callOptions = await this.getCallOptions(withVideo);
            let meetingLink = this.meetingInfo.link;
            this.callAgent.join({meetingLink}, callOptions);
        } catch (e) {
            console.error(`${this.intl.formatMessage({ id: "meeting_failed_to_join" })}: `, e);
            this.setState({ callError: `${this.intl.formatMessage({ id: "meeting_failed_to_join" })}: ` + e });
        }
    }

    async getCallOptions(withVideo) {
        let callOptions = {
            videoOptions: {
                localVideoStreams: undefined
            },
            audioOptions: {
                muted: false
            }
        };

        let cameraWarning = undefined;
        let speakerWarning = undefined;
        let microphoneWarning = undefined;

        // On iOS, device permissions are lost after a little while, so re-ask for permissions
        const permissions = await this.deviceManager.askDevicePermission({ audio: true, video: true });
        this.setState({permissions: permissions});

        const cameras = await this.deviceManager.getCameras();
        const cameraDevice = cameras[0];
        if (cameraDevice && cameraDevice?.id !== 'camera:') {
            this.setState({
                selectedCameraDeviceId: cameraDevice?.id,
                cameraDeviceOptions: cameras.map(camera => { return { key: camera.id, text: camera.name } })
            });
        }
        if (withVideo) {
            try {
                if (!cameraDevice || cameraDevice?.id === 'camera:') {
                    throw new Error(this.intl.formatMessage({ id: "meeting_no_camera_found" }));
                } else if (cameraDevice) {
                    callOptions.videoOptions = { localVideoStreams: [new LocalVideoStream(cameraDevice)] };
                }
            } catch (e) {
                cameraWarning = e.message;
            }
        }

        try {
            const speakers = await this.deviceManager.getSpeakers();
            const speakerDevice = speakers[0];
            if (!speakerDevice || speakerDevice.id === 'speaker:') {
                throw new Error(this.intl.formatMessage({ id: "meeting_no_speaker_found" }));
            } else if (speakerDevice) {
                this.setState({
                    selectedSpeakerDeviceId: speakerDevice.id,
                    speakerDeviceOptions: speakers.map(speaker => { return { key: speaker.id, text: speaker.name } })
                });
                await this.deviceManager.selectSpeaker(speakerDevice);
            }
        } catch (e) {
            speakerWarning = e.message;
        }

        try {
            const microphones = await this.deviceManager.getMicrophones();
            const microphoneDevice = microphones[0];
            if (!microphoneDevice || microphoneDevice.id === 'microphone:') {
                throw new Error(this.intl.formatMessage({ id: "meeting_no_mic_found" }));
            } else {
                this.setState({
                    selectedMicrophoneDeviceId: microphoneDevice.id,
                    microphoneDeviceOptions: microphones.map(microphone => { return { key: microphone.id, text: microphone.name } })
                });
                await this.deviceManager.selectMicrophone(microphoneDevice);
            }
        } catch (e) {
            microphoneWarning = e.message;
        }

        if (cameraWarning || speakerWarning || microphoneWarning) {
            this.setState({
                deviceManagerWarning:
                    `${cameraWarning ? cameraWarning + ' ' : ''}
                    ${speakerWarning ? speakerWarning + ' ' : ''}
                    ${microphoneWarning ? microphoneWarning + ' ' : ''}`
            });
        }

        return callOptions;
    }

    render() {        
        return (
            <div className="card meeting-card">
                {"/meeting/" + this.meetingInfo?.id !== window.location.pathname ? 
                <div className="ms-Grid-row text-center">
                    <h6>{this.intl.formatMessage({ id: "settings_withings_connection_failure" })}</h6>
                    <button className="meeting-button mt-3" onClick={() => this.props.history.push(routes.meetings)}>{this.intl.formatMessage({ id: "meeting_meetings" })}</button>
                </div>
                : <>
                    <div className="ms-Grid-row text-center">
                        <h4>{this.meetingInfo?.subject}</h4>
                    </div>
                    <MeetingUserInfo onLoggedIn={this.handleLogIn} />
                    {this.state.loggedIn && 
                        <div className="ms-Grid">                        
                            {
                                !this.state.call &&
                                <div className="ms-Grid-row mt-3 text-center">
                                    <p>{this.intl.formatMessage({ id: "meeting_joing_group_meeting" })}</p>
                                    <DefaultButton className="meeting-button"
                                        iconProps={{ iconName: 'Video', style: { verticalAlign: 'middle', fontSize: 'large' } }}
                                        text={this.intl.formatMessage({ id: "meeting_join_meeting" })}
                                        disabled={this.state.call || !this.state.loggedIn}
                                        onClick={() => this.joinTeamsMeeting(true)}>
                                    </DefaultButton>
                                </div>
                            }
                            {
                                this.state.call &&
                                <>
                                    <CallCard
                                        onHangUp={this.onHangUp}
                                        call={this.state.call}
                                        deviceManager={this.deviceManager}
                                        selectedCameraDeviceId={this.state.selectedCameraDeviceId}
                                        cameraDeviceOptions={this.state.cameraDeviceOptions}
                                        speakerDeviceOptions={this.state.speakerDeviceOptions}
                                        microphoneDeviceOptions={this.state.microphoneDeviceOptions}
                                        onShowCameraNotFoundWarning={(show) => { this.setState({ showCameraNotFoundWarning: show }) }}
                                        onShowSpeakerNotFoundWarning={(show) => { this.setState({ showSpeakerNotFoundWarning: show }) }}
                                        onShowMicrophoneNotFoundWarning={(show) => { this.setState({ showMicrophoneNotFoundWarning: show }) }} />
                                </>
                            }
                    </div>}
                </>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {pageInfo: state.pageInfo};
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => {
        return dispatch(setPageInformationAction(pageInfo))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Meeting)));