import ApiService from "./ApiService";

export default class GraphService {
    getObservation = async (code) => {
        return this.apiCall({
            method: "get",
            apiMethod: "observations/latest",
            data: null,
            params: {code: code}
        });
    }

    getData = async (params) => {        
        return this.apiCall({
            method: "get",
            apiMethod: "charts/data",
            data: null,
            params: params
        });
    }    
    
    getDetail = async(codes) => {
        return this.apiCall({
            method: "get",
            apiMethod: "observations/getdetail",
            data: null,
            params: {code: codes}
        });
    }
    
    getDetailHistory = async(params) => {
        return this.apiCall({
            method: "get",
            apiMethod: "observations/history",
            data: null,
            params
        });
    }
    
    apiCall = async (options) => {
        const service = new ApiService();
        const response = await service.apiCall(options);
        if(response.data !== null && response.data !== undefined) {
            return response.data;
        }
        return null;
    }

}
