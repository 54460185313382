import ApiService from "./ApiService";
export default class OverviewService {

    getDataWithCodes = async (codes) => {
        return await this.apiCall({
            method: "get",
            apiMethod: "observations/latest",
            data: null,
            params: {code: codes}
        });
    }

    getData = async () => {
        return await this.apiCall({
            method: "get",
            apiMethod: "observations/latest/registered",
            data: null
        });
    }

    getOnBoardingFlow = async(patientId) => {
        return this.apiCall({
            method: "get",
            apiMethod: "onboarding/get",
            data: null,
            params: {
                patientId: patientId
            }
        });
    }

    getGoals = async () => {
        return this.apiCall({
            method: "get",
            apiMethod: "goals/current",
            data: null,
            params: null,
        });
    }

    getPatientCarePlans = async() => {
        return this.apiCall({
            method: "get",
            apiMethod: "careplan",
            data: null,
            params: null
        })
    }

    createReport = async(report, carePlan) => {
        return this.apiCall({
            method: "post",
            apiMethod: `dashboard/patient/${carePlan}`,
            data: report,
            params: null,
            responseType: 'blob'
        })
    }

    apiCall = async (options) => {
        const service = new ApiService();
        const response = await service.apiCall(options);
        if(response.data !== null && response.data !== undefined) {
            return response.data;
        }
        return null;
    }
}
