import en from "./en.json";
import fr from "./fr.json";
import sv from "./sv.json";

export const defaultLocale = "en";

const messages = {
  "en": en,
  "fr": fr,
  "sv": sv,
};

const browserLang = window.navigator.language.split("-")[0];
const isBrowserLangSupported = Object.keys(messages).includes(browserLang);

// locale could be langCode-countryCode (en-US, fr-CA) or only langCode (en, fr);
// If user has not set language in settings then we get language from his browser
// and it could be langCode-countryCode. We share with backend whatever it is (en-US or en only)
// and backend filter it on its side so in the future we could adjust articles based
// not only on language but on language and country.
const locale = localStorage.getItem("lang") || (isBrowserLangSupported && browserLang) || defaultLocale;

export default {
  defaultLocale,
  locale,
  messages: messages[locale] || messages[defaultLocale]
}