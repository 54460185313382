import React from "react";
import {Chart} from "react-google-charts";
import moment from 'moment-with-locales-es6';
import {Row, Col, Card} from "react-bootstrap";
import GraphDetails from "./GraphDetails";
import GraphService from "../service/GraphService";
import {graphPanel, formatDetailsData, formatGraphData} from "../botflows";
import ChartNavigation from "./ChartNavigation";
import DetailsHistory from "./DetailsHistory";

export default class YearChart extends React.Component {
  dateFormat = 'YYYY-MM-DD';
  dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';

  constructor(props) {
    super(props);

    this.service = new GraphService();

    this.state = {
      graphData: {},
      details: [],
      allDetails: null,
      startTime: null,
      endTime: null
    }
  }

  componentDidMount() {
    const startDate = moment().startOf('year');
    const endDate = moment().endOf('year');

    this.getGraphData(startDate, endDate);
  }

  getGraphData = (startDate, endDate) => {
    
    this.setState({
      startTime: startDate.format(this.dateTimeFormat),
      endTime: endDate.format(this.dateTimeFormat)
    });

    const params = {
      start: startDate.format(this.dateFormat),
      end: endDate.format(this.dateFormat),
      panel: graphPanel[this.props.observationCode].name,
      aggregation: "month",
      culture: this.props.locale
    }

    this.service.getData(params).then((response) => {
      const details = formatDetailsData(response.parameterStatistics);

      const graphData = formatGraphData(
        this.props.columnData,
        response.periodParameterObservations,
        this.props.graphType
      );
      
      if (response !== undefined) {
        this.setState({
          graphData: graphData,
          details: details
        });
      }
    });
  }

  render() {
    const { intl } = this.props

    return (
      <Card className="bp-card card-tab">
        <Card.Body>
          <ChartNavigation
            period="year"
            onNavigate={this.getGraphData}
            locale={this.props.locale}
            intl={intl}
          />

          {(this.state.graphData !== undefined && this.state.graphData.length > 0) &&
          <Row>
            <Col style={{backgroundColor: "#f2fbff"}} className="my-3">
              <Chart
                width={'100%'}
                height={'100%'}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={this.state.graphData}
                graph_id={Date.now().toString()}
                options={this.props.graphOptions}
                rootProps={{'data-testid': '1'}}
              />
            </Col>
          </Row>
          }
          <GraphDetails details={this.state.details} {...this.props}/>
          {(this.props.observationCode === "22253000" || this.props.observationCode.includes("Pain.") || this.props.observationCode === "443450003") && 
            <DetailsHistory 
              intl={intl}
              code={this.props.observationCode}
              start={this.state.startTime}
              end={this.state.endTime}
            />
          }
        </Card.Body>
      </Card>
    );
  }
}
