import ApiService from "./ApiService";

export default class WithingsService {
    subscribe = async (code) => {
        return this.apiCall({
            method: "post",
            apiMethod: `withings/subscribe`,
            isTokenrequired: true,
            data: null,
            params: { approvalCode: code }
        });
    }
    getSubscriptions = async () => {
        return this.apiCall({
            method: "get",
            apiMethod: `withings/getsubscriptions`,
            isTokenrequired: true,
            data: null
        });
    }
    revokeSubscriptions = async () => {
        return this.apiCall({
            method: "delete",
            apiMethod: `withings/revokesubscriptions`,
            isTokenrequired: true,
            data: null
        });
    }
    apiCall = async (options) => {
        const service = new ApiService();
        const response = await service.apiCall(options);
        if(response.data !== null && response.data !== undefined) {
            return response.data;
        }
        return null;
    }
}
