import React from "react";
import IconHeart from "./Icons/IconHeart";
import IconPhone from "./Icons/IconPhone";
import IconRun from "./Icons/IconRun";
import IconUser from "./Icons/IconUser";
import IconNavReportActive from "./Icons/IconNavReportActive";
import IconNavCogActive from "./Icons/IconNavCogActive";
import IconNavHeartActive from "./Icons/IconNavHeartActive";
import IconNavReport from "./Icons/IconNavReport";
import IconNavCog from "./Icons/IconNavCog";
import IconNavHeart from "./Icons/IconNavHeart";
import IconJournal from "./Icons/IconJournal";
import IconCorona from "./Icons/IconCorona";
import IconLungs from "./Icons/IconLungs";
import IconCough from "./Icons/IconCough";
import IconTherm from "./Icons/IconTherm";
import IconOverview from "./Icons/IconOverview";
import IconMystats from "./Icons/IconMystats";
import IconFacts from "./Icons/IconFacts";
import IconSettings from "./Icons/IconSettings";
const Icon = ({ size, type = "heart" }) => {
return(
  <div className="icon" width={`${size}px`} height={`${size}px`}>
    {type === "corona" && <IconCorona />}
    {type === "cough" && <IconCough />}
    {type === "heart" && <IconHeart />}
    {type === "phone" && <IconPhone />}
    {type === "journal" && <IconJournal />}
    {type === "lungs" && <IconLungs />}
    {type === "navCogActive" && <IconNavCogActive />}
    {type === "navHeartActive" && <IconNavHeartActive />}
    {type === "navReportActive" && <IconNavReportActive />}
    {type === "navCog" && <IconNavCog />}
    {type === "navHeart" && <IconNavHeart />}
    {type === "navReport" && <IconNavReport />}
    {type === "run" && <IconRun />}
    {type === "therm" && <IconTherm />}
    {type === "user" && <IconUser />}
    {type === "overview" && <IconOverview />}
    {type === "mystats" && <IconMystats />}
    {type === "facts" && <IconFacts />}
    {type === "settings" && <IconSettings />}
  </div>
);
}
export default Icon;
