import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import loginImage from "../../assets/Login.jpg";
import login2 from "../../assets/login2.jpg";
import logoImage from "../../assets/icons-logo-naked@2x.png";
import { useOktaAuth } from "@okta/okta-react";
import "./login.css";
import routes from "../../routes/routes.js";
import { environment } from "../../environment";

const Login = (props) => {
  const searchParams = useLocation().search;
  const isMeeting = searchParams.includes('gotomeeting');
  const intl = useIntl();
  const { oktaAuth, authState } = useOktaAuth();

  const onLogin = async (loginInWithIdp = false) => {
    if(loginInWithIdp === true){
      await oktaAuth.signInWithRedirect({idp: environment.capioIdp });
      console.log('Logging in with idp')
    } else {
      await oktaAuth.signInWithRedirect();
      console.log('Logging in without idp')
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated === true) {
      window.location.replace(routes.overview);
      return;
    }
  }, [authState.isAuthenticated]);

  
  useEffect(() => {
    if(window.location.pathname === environment.capioPathname){
      console.log('Re-directing capio-logged in user');
      onLogin(true);
    }
  }, []);

  let loginRegisterLink = intl.formatMessage({ id: "login_register_link", defaultMessage: '' }).trim();
  let loginRegisterText = intl.formatMessage({ id: "login_register_text", defaultMessage: '' }).trim();

  if (loginRegisterLink === 'login_register_link') {
    loginRegisterLink = '';
  }

  if (loginRegisterText === 'login_register_text') {
    loginRegisterText = '';
  }  
  
  return (
    <div className="sc-pjtZy">
      <div id="ArtboardView" data-testid="artboardview" cursor="zoom-out" className="sc-psEpA gubjtE">
        <div className="sc-qQwsb kWQips">
          <div className="sc-qYRsW kMjjrq login-page">
            <div className="login-header d-flex justify-content-between align-items-end">
              <img aria-hidden="false" alt="Logo" className="login-logo" src={logoImage}/>
              <h4 className="login-title text-uppercase font-weight-bold">{intl.formatMessage({ id: "login_title" })}</h4>
            </div>

            <div className="position-relative">
              <img aria-hidden="false" alt="Login"  className="mb-2" src={isMeeting ? login2 : loginImage}/>
              <button
                variant="outline-info"
                className="login-button justify-content-center"
                onClick={() => onLogin()}
              >
                {`${intl.formatMessage({ id: "login_login" })} >`}
              </button>
            </div>
            
            <div className="register-section">
              <p dangerouslySetInnerHTML={isMeeting ? { __html: intl.formatMessage({ id: "meeting_login_text" }) } : { __html: intl.formatMessage({ id: "login_not_a_member" }) }} />
              {loginRegisterLink.length > 0 && (
                <p>
                  <a href={environment.issuer + "/signin/register"}>{`${loginRegisterLink}`}</a> {isMeeting ? `${intl.formatMessage({ id: "meeting_login_text_click" })}` : `${intl.formatMessage({ id: "login_register_text" })}`}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
