import ApiService from "./ApiService";

export default class StatisticsService {
    getPatients = async () => {
        return this.apiCall({
            method: "get",
            apiMethod: "patient/all",
            data: null,
            params: null,
            responseType: "text/plain"
        });
    }

    getAllObservations = async () => {
        return await this.apiCall({
            method: "get",
            apiMethod: "observations/all",
            data: null,
            params: null,
            responseType: "application/octet-stream"
        });
    }

    apiCall = async (options) => {
        const service = new ApiService();
        const response = await service.apiCall(options);
        if(response.data !== null && response.data !== undefined) {
            return response.data;
        }
        return null;
    }
}
