import React from "react";

const IconHeart = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="icon_heart"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M1,14.2143179 C1,27.6655136 19.0722357,37.7187547 20,38 C20.9277643,37.7187547 39,27.6655136 39,14.2143179 C39,11.2919236 37.9188862,8.83486239 35.7566586,6.84569108 C33.6864407,4.94856369 31.1689535,4 28.2016411,4 C24.2784504,4 21.5437181,5.67980147 20,9.03684765 C18.4562819,5.67980147 15.7215496,4 11.7983589,4 C8.83104654,4 6.31355932,4.94856369 4.2433414,6.84569108 C2.0811138,8.83486239 1,11.2919236 1,14.2143179"
        id="Path"
        fill="#069589"
      ></path>
    </g>
  </svg>
);

export default IconHeart;
