import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import OverviewService from "../service/OverviewService";
import { connect } from "react-redux";
import { setPageInformationAction } from "../redux/actions";
import { withRouter } from 'react-router-dom';
import routes, { ROUTE_TITLES } from "../routes/routes";
import moment from 'moment-with-locales-es6';
import { environment } from "../environment";
import { withOktaAuth } from "@okta/okta-react";
import { botflows_cards, botflows_scenario, formatGraphData, formatBloodpressureGraphData } from "../botflows";
import TextTruncate from 'react-text-truncate';
import { formatStatistics } from "../formatters";
import { injectIntl } from "react-intl";
import GraphService from "../service/GraphService";
import cx from "classnames";
import CreateReportModal from "./CreateReportModal";

class CardList extends React.Component {
  intl = this.props.intl;
  myOverviewService = new OverviewService();

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      article: {},
      carePlans: null
    }
  }

  componentDidMount() {
    this.getData();
    this.getGraphData();
    this.getCarePlans();
  }

  componentDidUpdate() {
    if (this.props.articles.length > 0 && !this.state.article.articleMetadata) {
      this.setState({ article: this.props.articles[0] });
    }
  }

  getCarePlans = () => {
      this.myOverviewService.getPatientCarePlans().then(response => {
          this.setState(
              { 
                  carePlans: response,
              });
      });
  }

  getGraphData = async () => {
    const service = new GraphService();
    const endDate = moment().format('YYYY-MM-DD');
    const startDate = moment().subtract(6, 'd').format('YYYY-MM-DD');

    const params = {
      start: startDate,
      end: endDate,
      aggregation: "weekday",
      culture: this.props.locale
    }

    let steps = await service.getData({ ...params, panel: "steps" });
    let weight = await service.getData({ ...params, panel: "weight" });
    let activity = await service.getData({ ...params, panel: "activity" });
    let bloodpressure = await service.getData({ ...params, panel: "bloodpressure" });
    let latestWeight = await service.getObservation("29463-7");

    steps = formatGraphData(null, steps.periodParameterObservations, null);
    weight = formatGraphData(null, weight.periodParameterObservations, null);
    activity = formatGraphData(null, activity.periodParameterObservations, null);
    bloodpressure = formatBloodpressureGraphData(null, bloodpressure.periodParameterObservations, null);

    this.setState({ goals: { steps, weight, activity, bloodpressure }, latestWeight: latestWeight["29463-7"]?.value?.[0]?.value });
  }

  getData = async () => {
    this.myOverviewService.getData().then((response) => {
      let overviewKeysSchema = []
      let keys = Object.keys(response)      
      keys.forEach(plan => {
        var obj = {};
        obj[plan] = plan;
        if(plan !== "22253000"){
          overviewKeysSchema.push(obj)
        }
      })
      this.setState({ data: formatStatistics(response, overviewKeysSchema) });
    });
  }
  
  createReportModal = async () =>  {
    if (typeof this.props.setModal !== 'function') {
        return;
    }

    this.props.setModal({
        content: (
            <CreateReportModal
                carePlans={this.state.carePlans}
                myService={this.myOverviewService}
                setModal={this.props.setModal}
            />
        )
    });
  }

  onRowClick = (path, item) => {
    const history = this.props.history;
    const scenario = botflows_scenario[item.scenario || "report"];
    const pageHeader = item.label ? 
    item.label.toLowerCase().includes("pain.") ? 
      `${this.intl.formatMessage({ id: "settings_school_pain" })} - ${this.intl.formatMessage({ id: item.label })}` 
      : this.intl.formatMessage({ id: item.label })
      : ROUTE_TITLES[routes.report]

    const pageInfo = {
      pageHeader,
      previousPath: this.props.previousPath,
      botTriggerAction: {
        scenario,
        card: botflows_cards[item.code]
      },
      observationCode: item.code || ""
    };
    this.props.setPageInformation(pageInfo);
    localStorage.setItem("botTriggerAction", `${scenario}#${botflows_cards[item.code]}`);
    localStorage.setItem("pageHeader", pageHeader);
    history.push({ pathname: path, state: { id: item.code || "" } });
  }

  openArticle = () => {
    const history = this.props.history;
    const pageInfo = {
      pageHeader: ROUTE_TITLES[routes.article],
      previousPath: this.props.previousPath,
      botTriggerAction: "",
      observationCode: ""
    };
    this.props.setPageInformation(pageInfo);
    history.push({ pathname: `${routes.article}/${this.state.article.articleMetadata.id}` });
  }

  goToGoalSetting = () => {
    const history = this.props.history;
    const pageInfo = {
      pageHeader: ROUTE_TITLES[routes.report],
      previousPath: this.props.previousPath,
      botTriggerAction: {
        scenario: botflows_scenario["report"],
        card: botflows_cards["goals"]
      },
      observationCode: "goals"
    };
    this.props.setPageInformation(pageInfo);
    localStorage.setItem("botTriggerAction", `${botflows_scenario["report"]}#${botflows_cards["goals"]}`);
    localStorage.setItem("pageHeader", ROUTE_TITLES[routes.report]);
    history.push({ pathname: "/report", state: { id: "goals" } });
  }

  render = () => {
    const activities = [
      { code: "41950-7", goalCode: "steps", label: this.intl.formatMessage({ id: "goals_steps" }) },
      { code: "68130003", goalCode: "activity", label: this.intl.formatMessage({ id: "goals_activity" }) }
    ];

    const weight = this.state?.goals?.weight;
    let lastNotNullWeight = weight?.filter(w => w && w[2]).pop()?.[2];
    let previousNotNullWeight = weight?.slice(0, weight.indexOf(lastNotNullWeight)).filter(w => w && w[2]).pop()?.[2];

    const areGoalsNotCompleted = !Object.keys(this.props.userGoals).every(goal => this.props.userGoals[goal]);
    
    return (
      <>
        {this.state.data !== undefined &&
          this.state.data.map((item, index) =>  { 
            const cardLabel = item.label ? 
            item.label.toLowerCase().includes("pain.") ? 
              `${this.intl.formatMessage({ id: "settings_school_pain" })} - ${this.intl.formatMessage({ id: item.label })}` 
              : this.intl.formatMessage({ id: item.label })
              : this.intl.formatMessage({ id: Object.keys(item)[0] })
            if(item.code === 'meeting'){
              return
            }
            return (
            <Col md={6} className="d-flex px-0 px-md-4" key={`card-${index}`}>
              <Card key={`card_${index}`} className="bp-card mb-2 mb-md-5" onClick={() => {
                this.onRowClick(routes.details, item);
              }}>
                <Card.Body>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-between">
                      <h4 className="font-weight-bold mt-2">{cardLabel}</h4>
                      {item?.notReported
                        ? <h5 className="text-right mb-0 pt-1 mt-2">{this.intl.formatMessage({ id: item.value })}</h5>
                        : <h2 className="text-right mb-0">{item.value}</h2>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-between">
                      {item.date && <small>{moment(item.date).format(environment.dateFormat)}</small>}
                      {item.unit && <small>{this.intl.formatMessage({ id: item.unit })}</small>}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          )})}

        <Col md={6} className="d-flex px-0 px-md-4">
          <Card className="bp-card mb-2 mb-md-5">
            <Card.Body>
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <h5 className="font-weight-bold text-uppercase mb-0">{this.intl.formatMessage({ id: "daily_report_today" })}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <button
                    className="register-button ml-n2 mt-3"
                    onClick={() => this.onRowClick(routes.report, { scenario: "report" })}
                  >{this.intl.formatMessage({ id: "daily_report_report" })}</button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} className="d-flex px-0 px-md-4">
          <Card className="bp-card mb-2 mb-md-5">
            <Card.Body>
              <h5
                onClick={() => this.goToGoalSetting()}
                className="mb-2 font-weight-bold text-center text-uppercase"
              >
                {this.intl.formatMessage({ id: "goals_mygoals" })}
              </h5>

              {areGoalsNotCompleted && (
                <h6 onClick={() => this.onRowClick(routes.report, { code: "goals" })}>{this.intl.formatMessage({ id: "goals_missing_goal" })}</h6>
              )}

              {activities.map((item, index) => (
                this.props.userGoals[item.goalCode] && (
                  <Row className="mb-4" key={`activity-${index}`} onClick={() => {
                    this.onRowClick(routes.details, item);
                  }}>
                    <Col xs={12} className="d-flex justify-content-between">
                      <h4 className="font-weight-bold">{this.intl.formatMessage({ id: item.label })}</h4>
                      <h2 className="text-right mb-0">{this.props.userGoals[item.goalCode]}</h2>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-end">
                      {this.state.goals?.[item.goalCode].slice(1, 8).map(step => (
                        <div className="ml-3 d-flex flex-column">
                          <span className={cx("goal-point", {
                            "goal__no-data": !step[2],
                            "goal__equal-above": step[2] !== null && step[2] >= Number(this.props.userGoals[item.goalCode])
                          })} />
                          <small className="text-center goal-day_label">{step[0]}</small>
                        </div>
                      ))}
                    </Col>
                  </Row>
                )
              ))}

              {this.props.userGoals.dia && this.props.userGoals.sys && (
                <Row onClick={() => {
                  this.onRowClick(routes.details, { code: "85354-9" });
                }}>
                  <Col xs={12} className="d-flex justify-content-between">
                    <h4 className="font-weight-bold mt-2">{this.intl.formatMessage({ id: "85354-9" })}</h4>
                    <div className="d-flex align-items-center">
                      <h2 className="text-right mb-0 ml-2">{`${this.props.userGoals.sys}/${this.props.userGoals.dia}`}</h2>
                    </div>
                  </Col>
                  <Col xs={12} className="d-flex justify-content-end">
                    {this.state.goals?.["bloodpressure"].slice(1, 8).map(step => {
                      return (
                      <div className="ml-3 d-flex flex-column">
                        <span className={cx("goal-point", {
                          "goal__no-data": !step[2],
                          "goal__equal-above": step[2] !== null && step[2] <= Number(this.props.userGoals.sys) && step[3] !== null && step[3] <= Number(this.props.userGoals.dia)
                        })} />
                        <small className="text-center goal-day_label">{step[0]}</small>
                      </div>
                    )})}
                  </Col>
                </Row>
              )}

              {this.props.userGoals.weight && (
                <Row onClick={() => {
                  this.onRowClick(routes.details, { code: "29463-7" });
                }}>
                  <Col xs={12} className="d-flex justify-content-between">
                    <h4 className="font-weight-bold mt-2">{this.intl.formatMessage({ id: "goals_weight" })}</h4>
                    <div className="d-flex align-items-center">
                      <span className={lastNotNullWeight &&  previousNotNullWeight && cx("weight-indicator", {
                        "indicator-up": lastNotNullWeight > previousNotNullWeight,
                        "indicator-right": lastNotNullWeight === previousNotNullWeight,
                        "indicator-down": lastNotNullWeight < previousNotNullWeight
                      })} />
                      <h2 className="text-right mb-0 ml-2">{lastNotNullWeight}</h2>
                    </div>
                  </Col>
                  <Col xs={10}>
                    <h6>{this.intl.formatMessage({ id: "goals_weight_trend" }, { "weight": Math.abs(this.props.userGoals.weight - this.state.latestWeight) })}</h6>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} className="d-flex px-0 px-md-4">
            <Card className="bp-card mb-2 mb-md-5">
                <Card.Body>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center">
                            <h5 className="font-weight-bold text-uppercase mb-0">{this.intl.formatMessage({ id: "settings_report" })}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center">
                            <button
                              className="register-button mt-3"
                              onClick={this.createReportModal}
                            >{this.intl.formatMessage({ id: "settings_report_create" })}</button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>

        {this.state.article.articleMetadata &&
          <Col md={6} className="px-0 px-md-4">
            <Card className="bp-card mb-2 mb-md-5" onClick={this.openArticle}>
              <Card.Body className="text-center">
                <div className="article-wrapper position-relative">
                  <h3 className="overview-article-header">
                    <TextTruncate
                      line={2}
                      truncateText={"..."}
                      text={this.state.article.articleMetadata.title}
                    />
                  </h3>
                  <p className="overview-article-subheader text-left mb-0">
                    <TextTruncate
                      line={3}
                      truncateText={"..."}
                      text={this.state.article.articleMetadata.subtitle}
                    />
                  </p>
                  <img className="card-image" aria-hidden="false" alt="Login"
                    src={this.state.article.articleMetadata.thumbnailUri} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        }

        <Col md={6} className="d-flex px-0 px-md-4">
          <Card className="bp-card mb-2 mb-md-5">
            <Card.Body>
              <Row>
                <Col xs={12} className="text-center">
                    <h5 className="font-weight-bold mb-0">{this.intl.formatMessage({ id: "overview_feedback_title" })}</h5>
                    <p className="mb-0">{this.intl.formatMessage({ id: "overview_feedback_copy" })}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <button className="register-button mt-3" onClick={()=>{window.open(environment.feedbackFormUrl)}} type="button" 
                    >{this.intl.formatMessage({ id: "login_register_link" })}</button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userGoals: state.userGoals,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOktaAuth(injectIntl(CardList))));
