import React from "react";
import CardList from "./CardList";
import routes from "../routes/routes";
import OverviewService from "../service/OverviewService";
import { botflows_cards } from "../botflows";
import { environment } from "../environment";

export class OverviewComponent extends React.Component {
    goalsCodes = environment.goals.split(",");
    service = new OverviewService();

    componentDidMount() {
        const goals = {};
        
        this.service.getGoals().then(response => {
            this.goalsCodes.forEach(goalCode => {
                const goal = response?.goals.filter(goal => goal.code[0]?.code === goalCode);
                goals[botflows_cards[goal[0]?.code[0]?.code]] = goal[0]?.value?.value;
            });

            this.props.setUserGoals(goals);
        }).then(() => {
            if (this.props.directLine && this.props.articles.length === 0) {
                this.props.getArticles("lifestyle");
            }
        });
    }

    render = () => {
        return (
            <div className="row">
                <CardList previousPath={routes.overview} {...this.props} />
            </div>
        );
    };
}
