import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter, useLocation } from "react-router-dom";
import routes from "../routes/routes";
import { Security, LoginCallback } from "@okta/okta-react";
import { oktaConfig } from "../oktaConfig";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import ProtectedRoute from "../routes/ProtectedRoute";
import Login from "./Login/Login";
import OverviewWrapper from "./OverviewWrapper";
import Settings from "./Settings";
import Statistics from "./Statistics";
import Observations from "./Observations";
import Caisr from "./Caisr";
import Facts from "./Facts";
import Meeting from "./Meeting/Meeting";
import Meetings from "./Meetings";
import MyStats from "./MyStats";
import Details from "./Details";
import Article from "./Article";
import Withings from "./Withings";
import Botflow from "./Botflow";
import Conditions from "./Conditions/Conditions";
import { createStore } from "botframework-webchat";
import { callActivity, initializeDirectLine } from "../setupHealthBot";
import moment from 'moment-with-locales-es6';
import { defaultLocale } from "../lang/intlConfig";
import { botflows_scenario } from "../botflows";
import { useIntl } from "react-intl";
import CookieConsent from "react-cookie-consent";
import Main from "./Main";
import Modal from "./Modal";

moment.locale(localStorage.getItem("lang") || defaultLocale);

const App = ({ locale, history }) => {
  const [directLine, setDirectLine] = useState();
  const [botUser, setBotUser] = useState({});
  const [articles, setArticles] = useState([]);
  const [modal, setModal] = useState({});
  const intl = useIntl();

  const searchParams = useLocation().search;
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const storeMiddleware = () => next => action => {
    if (action.type === 'DIRECT_LINE/DISCONNECT_FULFILLED') {
      setDirectLine(null);
      setStore(createStore({}, storeMiddleware));
      initializeDirectLine();
    }
    return next(action)
  };

  const [store, setStore] = useState(createStore({}, storeMiddleware));

  const onActivityUpdate = (newEntries) => {
    const location = Array.isArray(newEntries) ? newEntries[0]?.["goto"] : null

    if (!location) {
      return
    }

    if (Object.keys(routes).includes(location)) {
      window.location.href = routes[location];
    } else {
      history.goBack();
    }
  };

  const initDirectLine = () => {
    (async () => {
      const [directLine, currentUser] = await initializeDirectLine();
      setBotUser(currentUser);
      setDirectLine(directLine);
    })();
  }

  const getArticles = (page) => {
    if (directLine !== undefined && directLine !== null) {
      (async () => {
        await callActivity(
          directLine,
          locale,
          0,
          botUser,
          getArticleData,
          {
            scenario: botflows_scenario["article"],
            page: page || "lifestyle"
          }
        );
      })();
    }
  }

  const getArticleData = (response) => {
    let articlesArr = [];
    if (response[0] && response[0].articles) {
      for (const [key, value] of Object.entries(response[0].articles)) {
        articlesArr.push(value);
      }
      setArticles(articlesArr);
    }
  };

  /**
   * Transfer specified query parameters to session storage
   */
  useEffect(() => {
    const transferParams = {
      addcareplan: 'addCarePlan',
      gotomeeting: 'gotomeeting',
      careunit: 'careunit'
    }

    Object.keys(transferParams).forEach((key) => {
      const storageItemKey = transferParams[key]

      const queryParamValue = query.get(key)
      const storageItemValue = sessionStorage.getItem(storageItemKey)

      if (typeof queryParamValue === 'string' && queryParamValue !== storageItemValue) {
        sessionStorage.setItem(storageItemKey, queryParamValue);
      }
    })
  }, [searchParams, query])

  useEffect(() => {
    initDirectLine();
    document.title = intl.formatMessage({ id: "title" })
  }, []);

  const disconnectDirectLine = () => {
    if (directLine) {
      directLine.activity$.complete();
    }
    initDirectLine();
  }

  initializeIcons();

  return (
    <>
      <Security oktaAuth={oktaConfig}>
        <Main setModal={setModal}>
          <Switch>
            <Route
              exact
              path={routes.login}
              component={Login}
            />
            <Route
              exact
              path={routes.capio}
              component={Login}
            />
            <Route path="/callback">
              <LoginCallback />
            </Route>
            <Route
              exact
              path={routes.conditions}
              component={Conditions}
            />
            <ProtectedRoute
              exact
              path={routes.overview}
              component={OverviewWrapper}
              articles={articles}
              getArticles={getArticles}
              locale={locale}
              onActivityUpdate={onActivityUpdate}
              directLine={directLine}
              user={botUser}
              disconnectDirectLine={disconnectDirectLine}
              setModal={setModal}
            />
            <ProtectedRoute
              exact
              path={routes.settings}
              component={Settings}
              setModal={setModal}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.statistics}
              component={Statistics}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.observations}
              component={Observations}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.tracks}
              component={Botflow}
              locale={locale}
              stateStore={store}
              onActivityUpdate={onActivityUpdate}
              directLine={directLine}
              user={botUser}
              disconnectDirectLine={disconnectDirectLine}
            />
            <ProtectedRoute
              exact
              path={routes.facts}
              component={Facts}
              articles={articles}
              getArticles={getArticles}
              directLine={directLine}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.meetings}
              component={Meetings}
              locale={locale}
            />
            <ProtectedRoute
              path="/meeting/:id"
              component={Meeting}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.mystats}
              component={MyStats}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.details}
              component={Details}
              locale={locale}
            />
            <ProtectedRoute
              path="/article/:id"
              component={Article}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.withings}
              component={Withings}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.caisr}
              component={Caisr}
              locale={locale}
            />
            <ProtectedRoute
              exact
              path={routes.reportcaisr}
              component={Botflow}
              locale={locale}
              stateStore={store}
              onActivityUpdate={onActivityUpdate}
              directLine={directLine}
              user={botUser}
              disconnectDirectLine={disconnectDirectLine}
            />
            <ProtectedRoute
              exact
              path={routes.caisr_1177}
              component={Botflow}
              locale={locale}
              stateStore={store}
              onActivityUpdate={onActivityUpdate}
              directLine={directLine}
              user={botUser}
              disconnectDirectLine={disconnectDirectLine}
            />
            <ProtectedRoute
              exact
              path={routes.report}
              component={Botflow}
              locale={locale}
              stateStore={store}
              onActivityUpdate={onActivityUpdate}
              directLine={directLine}
              user={botUser}
              disconnectDirectLine={disconnectDirectLine}
            />
            <ProtectedRoute
              exact
              path={routes.addmeeting}
              component={Botflow}
              locale={locale}
              stateStore={store}
              onActivityUpdate={onActivityUpdate}
              directLine={directLine}
              user={botUser}
              disconnectDirectLine={disconnectDirectLine}
            />
            <ProtectedRoute
              exact
              path={routes.addcaisr}
              component={Botflow}
              locale={locale}
              stateStore={store}
              onActivityUpdate={onActivityUpdate}
              directLine={directLine}
              user={botUser}
              disconnectDirectLine={disconnectDirectLine}
            />
          </Switch>

          <Modal
            modal={modal}
            onClose={() => {
              setModal({});
            }}
          />
        </Main>
      </Security>
      <CookieConsent
        location="bottom"
        buttonText={intl.formatMessage({ id: "cookie_consent_button" }) + " >"}
        overlay={true}
        buttonStyle={{
          background: "#ffffff",
          borderRadius: "25px",
          color: "#0094d2",
          margin: "10px 25px 10px 25px",
        }}
        style={{
          alignItems: "center",
          background: "#0094d2",padding: '30px 0px',
        }}
        contentStyle={{
          margin: "0px 25px",
        }}
        overlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.6)"
        }}
      >{intl.formatMessage({ id: "cookie_consent_message" })}
      </CookieConsent>
    </>
  );
}

export default withRouter(App);
