import React from "react";
import { connect } from "react-redux";
import { withOktaAuth } from "@okta/okta-react";
import { injectIntl } from "react-intl";

class Header extends React.Component {
    render = () => {
        return (
            <header className="page-header">{this.props.pageInfo.pageHeader}</header>
        );
    }
}
const mapStateToProps = (state) => {
    if (!state.pageInfo.pageHeader) {
        const info = {
            pageHeader: localStorage.getItem("pageHeader"),
        }
        
        return {pageInfo: info};
    }
    return { pageInfo: state.pageInfo };
};

export default connect(mapStateToProps)(withOktaAuth(injectIntl(Header)));
