import React from "react";
import {Row, Col, Card} from "react-bootstrap";
import {injectIntl} from "react-intl";
import routes, {ROUTE_TITLES} from "../routes/routes";
import MyStatsService from "../service/MyStatsService";
import {connect} from "react-redux";
import {setPageInformationAction} from "../redux/actions";
import {withRouter} from 'react-router-dom';
import moment from 'moment-with-locales-es6';
import {environment} from "../environment";
import {botflows_cards, botflows_scenario} from "../botflows";
import { createSchema, formatStatisticsArr } from "../formatters";

class MyStats extends React.Component {
  intl = this.props.intl;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true
    }
  }

  componentDidMount = async () => {
    const service = new MyStatsService();

    const patient = await service.getPatient();
    const observations = await service.getObservations();
    
    const sortedObsArr =  Object.entries(observations).sort(([,a],[,b]) => {
      let dateA = a?.effectiveDateTime.replace(/[^0-9]/g, '');
      let dateB = b?.effectiveDateTime.replace(/[^0-9]/g, '');
      return dateA > dateB ? -1 : 1
    })

    let obsArr = [];

    sortedObsArr.forEach(element => {
      obsArr.push({[element[0]]: element[1]})
    });    

    const formattedObservations = formatStatisticsArr(createSchema(sortedObsArr), sortedObsArr);

    formattedObservations.forEach(observation => {
      if (Object.keys(observation)[0] === "mystats_about_you") {
        observation[Object.keys(observation)[0]].push(...[
          {
            code: "bday",
            label: "mystats_birthday",
            value: moment(patient?.birthDate).format(environment.dateFormat)
          },
          {
            code: "gender",
            label: "mystats_gender",
            value: `mystats_gender_${patient?.gender.toLowerCase()}`
          }
        ])
      }
    });

    this.setState({ data: formattedObservations, loading: false });
  }

  onRowClick = (item) => {
    const code = Object.keys(item)[0];
    const isCodeNested = Array.isArray(item[code])
    const cardCode = isCodeNested ? code : item.code;

    const history = this.props.history;

    const pageHeader = item.label ? 
    item.label.toLowerCase().includes("pain.") ? 
      `${this.intl.formatMessage({ id: "settings_school_pain" })} - ${this.intl.formatMessage({ id: item.label })}` 
      : this.intl.formatMessage({ id: item.label })
      : ROUTE_TITLES[routes.report]

      const pageInfo = {
      pageHeader,
      previousPath: routes.mystats,
      botTriggerAction: {
        scenario: botflows_scenario[item.label.toLowerCase().includes("85354-9") ? "report_single" : "report"],
        card: botflows_cards[cardCode]
      },
      observationCode: cardCode || ""
    };

    this.props.setPageInformation(pageInfo);

    localStorage.setItem("botTriggerAction", `${botflows_scenario[item.label.toLowerCase().includes("85354-9") ? "report_single" : "report"]}#${botflows_cards[cardCode]}`);
    localStorage.setItem("pageHeader", pageHeader);

    history.push({
      pathname: isCodeNested ? routes.report : routes.details,
      state: { id: cardCode || "" }
    });
  }

  getUnit(unit) {
    let translation

    try {
      translation = this.intl.formatMessage({ id: unit.toLowerCase() })
    } catch (e) {
      translation = unit
    }

    return translation
  }

  render = () => {
    return (
      <Row>
        {this.state.loading ? <div className="loading-spinner"></div> : this.state.data.map((item, index) => {
          const cardLabel = item.label ? 
          item.label.toLowerCase().includes("pain.") ? 
            `${this.intl.formatMessage({ id: "settings_school_pain" })} - ${this.intl.formatMessage({ id: item.label })}` 
            : this.intl.formatMessage({ id: item.label })
            : this.intl.formatMessage({ id: Object.keys(item)[0] })

            return <Col md={6} className="d-flex px-0 px-md-4" key={index}>
            <Card key={`card_${index}`} className="bp-card mb-2 mb-md-5" onClick={() => {
              this.onRowClick(item);
            }}>
              <Card.Body>
                <Row>
                  <Col xs={12} className="d-flex justify-content-between">
                    <h4 className="font-weight-bold mt-2">{cardLabel}</h4>
                    {item?.notReported
                      ? <h5 className="text-right mb-0 pt-1 mt-2">{this.intl.formatMessage({ id: item.value})}</h5>
                      : <h2 className="text-right mb-0">{item.value}</h2>
                    }
                  </Col>
                </Row>
                <Row>
                  {typeof item[Object.keys(item)[0]] === "string"
                    ? <Col xs={12} className="d-flex justify-content-between">
                        {item.date && <small>{moment(item.date).format(environment.dateFormat)}</small>}
                        {item.unit && <small>{this.getUnit(item.unit)}</small>}
                      </Col>
                    : <Col xs={12}>
                        {item[Object.keys(item)[0]].map(subitem => {
                          return (
                          subitem.value && (
                            <div className="d-flex justify-content-end" key={subitem.code}>
                              <p className="font-weight-normal text-right mr-1 mb-0">{this.intl.formatMessage({ id: subitem.label })}:</p>
                              <p className="d-inline font-weight-bold text-right text-capitalize mb-0">{typeof subitem.value === 'number' || subitem?.code === 'bday' ? subitem.value : this.intl.formatMessage({ id: subitem.value })}</p>
                            </div>
                          )
                        )})}
                      </Col>
                  }
                </Row>
              </Card.Body>
            </Card>
          </Col>
        })}
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}
export default connect(null, mapDispatchToProps)(withRouter(injectIntl(MyStats)));
