import React from "react";
import {Card, Row, Col, ButtonGroup, Button} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import {connect} from "react-redux";
import {setPageInformationAction} from "../redux/actions";
import routes, {ROUTE_TITLES} from "../routes/routes";
import InfiniteScroll from 'react-infinite-scroll-component'
import {injectIntl} from "react-intl";

class Facts extends React.Component {
  images = require.context('../assets', true);

  constructor(props) {
    super(props);
    this.state = {
      activeCategory: "medical",
    }
  }

  componentDidMount() {
    this.props.getArticles(this.state.activeCategory);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.activeCategory !== this.state.activeCategory
      || (!prevProps.directLine && this.props.directLine)
    ) {
      this.props.getArticles(this.state.activeCategory);
    }
  }

  openArticle = (articleId) => {
    const history = this.props.history;
    const pageInfo = {
      pageHeader: ROUTE_TITLES[routes.article],
      previousPath: routes.facts,
      botTriggerAction: "",
      observationCode: ""
    };
    this.props.setPageInformation(pageInfo);
    history.push({pathname: `${routes.article}/${articleId}`});
  }

  fetchArticles = () => {
    this.props.getArticles(this.state.activeCategory);
  }

  render = () => {
    const { intl } = this.props;

    return (
      <>
        <Row>
          <Col className="mb-0 mb-md-5 px-0 px-md-4 d-flex justify-content-center">
            <ButtonGroup className="flex-grow-1 flex-md-grow-0.5">
              <Button
                active={this.state.activeCategory === "medical"}
                onClick={() => this.setState({ activeCategory: "medical" })}
                className="category-btn w-50"
              >
                {intl.formatMessage({ id: "articles_category_medical" })}
              </Button>

              <Button
                active={this.state.activeCategory === "lifestyle"}
                onClick={() => this.setState({ activeCategory: "lifestyle" })}
                className="category-btn w-50"
              >
                {intl.formatMessage({ id: "articles_category_lifestyle" })}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <InfiniteScroll
          dataLength={this.props.articles.length}
          next={this.fetchArticles}
          hasMore={false}
          loader={<h4>Loading...</h4>}
          style={{ overflow: "unset" }}
        >
          <Row>
            {this.props.articles.filter(item => item.articleMetadata.category === this.state.activeCategory).map((item, index) => (
              item.articleMetadata &&
              <Col md={6} key={`fg_${index}`} className="d-flex flex-column mb-2 mb-md-5 px-0 px-md-4">
                <Card className="bp-card" key={`card_${index}`}>
                  <Card.Body
                    className="text-center"
                    onClick={() => {
                      this.openArticle(item.articleMetadata.id)
                    }}
                    key={`cardbody_${index}`}
                  >
                    <div className="article-wrapper position-relative">
                      <h3 className="overview-article-header" key={`header_${index}`}>
                        <TextTruncate
                          line={2}
                          truncateText={"..."}
                          text={item.articleMetadata.title}
                        />
                      </h3>
                      <p className="overview-article-subheader text-left mb-0" key={`p_${index}`}>
                        <TextTruncate
                          line={3}
                          truncateText={"..."}
                          text={item.articleMetadata.subtitle}
                        />
                      </p>
                      <img className="card-image" aria-hidden="false" alt="Login" src={item.articleMetadata.thumbnailUri}/>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}

export default connect(null, mapDispatchToProps)(withRouter(injectIntl(Facts)));
