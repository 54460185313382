import { connect } from "react-redux";
import { setPageInformationAction } from "../redux/actions";

const Main = ({ children }) => {
    return children;
};

const mapStateToProps = (state) => {
  return {
    pageInfo: state.pageInfo
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
