import React from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "./Footer";
import Header from "./Header";
import { withOktaAuth } from "@okta/okta-react";
import {setPageInformationAction, setUserInformationAction} from "../../redux/actions";
import cx from "classnames";
import {ROUTE_TITLES} from "../../routes/routes";

class Layout extends React.Component {
    constructor(props) {
        super(props);

        const userInfo = {
            userName: this.props.authState.accessToken.claims.sub,
            patientId: this.props.authState.accessToken.claims.uid,
            token: this.props.authState.idToken.idToken
        };
        this.props.setUserInformation(userInfo);
    }

    componentDidMount = () => {
        if (this.props.path) {
            this.props.setPageInformation({ pageHeader: ROUTE_TITLES[this.props.path] })
        }
    }

    render = () => {
        const isArticlePage = this.props.match.path === "/article/:id";

        const isNarrowContainer = ["/details", "/report"].includes(this.props.path)

        return (
            <div className={cx("sc-pjtZy hlrJtm", {
                "white-background": isArticlePage
            })}>
                <div id="ArtboardView" data-testid="artboardview" cursor="zoom-out" className="sc-psEpA">
                    <div className="sc-qQwsb kWQips">
                        <div className="sc-qYRsW">
                            <div aria-busy="false" className="sc-qWRQj eEaJsF sc-pBmFw gLkaZA"> 
                                <Header/>  
                                <main className="h-100">
                                    <Container
                                      className={cx("page-section mt-md-5", {
                                        "article-page": isArticlePage,
                                        "narrow-container": isNarrowContainer
                                      })}
                                    >{this.props.children}</Container>
                                    <Footer {...this.props}/>
                                </main>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchtoProps = (dispatch) => {
    return {
        setUserInformation: (userInfo) => dispatch(setUserInformationAction(userInfo)),
        setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo))
    }
}

export default connect(null,mapDispatchtoProps)(withRouter(withOktaAuth(Layout)));
