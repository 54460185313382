import React from "react";
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { v4 as uuid } from 'uuid';
import MeetingService from "../../service/MeetingService";
import {injectIntl} from "react-intl";
import {withRouter} from 'react-router-dom';
const createDOMPurify = require('dompurify');

class MeetingUserInfo extends React.Component {
    DOMPurify = createDOMPurify(window);
    service = new MeetingService();

    constructor(props) {
        super(props);
        this.userDetailsResponse = undefined;
        this.displayName = "Participant" + Math.floor(Math.random()*1000);
        this.clientTag = uuid();
        this.meetingInfo = sessionStorage.getItem('meetinginfo') ? JSON.parse(sessionStorage.getItem('meetinginfo')) : null;
        this.state = {
            showUserProvisioningAndSdkInitializationCode: false,
            showSpinner: false,
            disableInitializeButton: false,
            loggedIn: false,
            myToken: null
        }
    }

    provisionNewUserWithToken = async () => {
        try {this.setState({ showSpinner: true, disableInitializeButton: true });
           await this.props.onLoggedIn({ token: this.state.myToken ?? null, displayName: this.displayName, clientTag: this.clientTag });
            this.setState({ loggedIn: true });
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ disableInitializeButton: false, showSpinner: false });
        }
    }

    onInputChange = (e) => {
        this.displayName = e.target?.value ?? this.intl.formatMessage({ id: "meeting_participant" }) + Math.floor(Math.random()*1000)
    }

    
    componentDidMount = async () => {
        const acsToken = await this.service.getMeetingRequirements();
        this.setState({ myToken: acsToken?.userAccessToken });
    }

    render() {
        const { intl } = this.props;
        return (
            !this.state.loggedIn &&
                <>
                    {
                        this.state.showSpinner &&
                        <div className="custom-row align-items-center mt-4">
                            <div className="loader"> </div>
                            <div className="ml-2">{intl.formatMessage({ id: "settings_withings_loading_text" })}</div>
                        </div>
                    }
                    {
                        !this.state.loggedIn && !this.state.showSpinner &&
                        <>  
                            <div className="text-center" dangerouslySetInnerHTML={{__html: this.DOMPurify.sanitize(this.meetingInfo?.content)}}></div>
                            <div className="mt-4">
                                <TextField className="meeting-login-alias"
                                            defaultValue={this.displayName}
                                            label={`${intl.formatMessage({ id: "meeting_display_name" })}:`}
                                            onChange={(e) => { this.onInputChange(e) }} />
                            </div>
                            <div className="text-center">
                                <DefaultButton className="meeting-button mt-3"
                                    label="Select display name" 
                                    disabled={this.state.disableInitializeButton}
                                    onClick={() => this.provisionNewUserWithToken()}>
                                        {intl.formatMessage({ id: "meeting_select_display_name" })}
                                </DefaultButton>
                            </div>
                        </>
                    }
                </>
        );
    }
}

export default (withRouter(injectIntl(MeetingUserInfo)));
