import ApiService from "./ApiService";

export default class ArticleService {
    getArticles = async () => {
        return this.apiCall({
            method: "get",
            apiMethod: `articles/recommended`,
            data: null,
            params: null
        });
    }
    getArticle = async (articleId) => {
        return this.apiCall({
            method: "get",
            apiMethod:  `articles/raw/${articleId}`,
            data: null,
            params: null
        });
    }
    rateArticle = async (data) => {
        return this.apiCall({
            method: "post",
            apiMethod: "articles/read",
            data: data,
            params: null,
        })
    }
    apiCall = async (options) => {
        const service = new ApiService();
        const response = await service.apiCall(options);
        if(response.data !== null && response.data !== undefined) {
            return response.data;
        }
        return null;
    }
}
