import * as actionTypes from "./action-types";

export const setUserInformationAction = (userInfo) => {
    return {type: actionTypes.SET_USER_INFORMATION, payload: userInfo};
};

export const setPageInformationAction = (pageInfo) => {
    return {type: actionTypes.SET_PAGE_INFORMATION, payload: pageInfo};
};

export const setUserGoals = goals => ({ type: actionTypes.SET_USER_GOALS, payload: goals });
