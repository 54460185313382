import React from "react";
import {Row, Container, Col} from "react-bootstrap";
import "./Conditions.css";
import {useIntl} from "react-intl";
import intlConfig from "../../lang/intlConfig";
import logoImage from "../../assets/ramsay_logo.png";

const {messages} = intlConfig;

const Conditions = () => {
  const intl = useIntl();

  let previousLevel = 0;
  let lists = [];

  const messageKeys = Object.keys(messages).filter(key => key.indexOf("conditions_") !== -1);

  return (
    <Container className="conditions-page">
      <Row>
        <Col xs={12} className="d-flex align-items-center justify-content-between">
          <button className="back-button" onClick={() => window.history.go(-1)}>&lt;</button>
          <img aria-hidden="false" alt="Logo" className="login-logo" src={logoImage}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h3 className="text-center note mt-4" dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "conditions-note"})}} />

          <h3 className="text-center mt-4">{`${intl.formatMessage({id: "conditions-title"})}`}</h3>

          <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "conditions-subtitle"})}}></p>

          {messageKeys.map(key => {
              const currentLevel = key.split("_").length;
              let element = null;

              if (currentLevel === 2) element = <h4>{intl.formatMessage({id: key})}</h4>;
              if (currentLevel === 3) element = <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: key})}}/>;
              if (currentLevel === 4) element = <li dangerouslySetInnerHTML={{__html: intl.formatMessage({id: key})}}/>;

              if (
                (currentLevel === 4 && previousLevel !== 4) ||
                (currentLevel === 4 && previousLevel === 4)
              ) {
                lists.push(element);
              }

              if (currentLevel !== 4 && previousLevel === 4) {
                previousLevel = currentLevel;

                return (
                  <>
                    <ul>{lists}</ul>
                    {element}
                  </>
                )
              }

              previousLevel = currentLevel;

              if (currentLevel !== 4) return element;

              return null;
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default Conditions;
