import { createIntl } from "react-intl";
import intlConfig from "./lang/intlConfig";

const intl = createIntl(intlConfig);

export const botflows_scenario = {
    "meeting_onboarding": "meeting_onboarding",
    "caisr_onboarding": "caisr_onboarding",
    "meeting_add_care_unit": "add_care_unit_to_meeting_patient",
    "onboarding" : "main_onboarding",
    "article" : "hypertension_articles",
    "report": "CarePlans_main_Reporting",
    "report_caisr": "general_enter_caisr",
    "report_single": "hypertension_report",
    "settings_careplan": "care_plan_management",
    "caisr_1177": "caisr_1177",
}

const painPointsCards = {
    "Pain.22253000": "pain",
    "Pain.25064002": "pain",
    "Pain.398057008": "pain",
    "Pain.37796009": "pain",
    "Pain.95668009": "pain",
    "Pain.16001004": "pain",
    "Pain.280536008": "pain",
    "Pain.69536005": "pain",
    "Pain.279069000": "pain",
    "Pain.304036007": "pain",
    "Pain.77568009": "pain",
    "Pain.267981009": "pain",
    "Pain.267982002": "pain",
    "Pain.362677002": "pain",
    "Pain.45326000": "pain",
    "Pain.53120007": "pain",
    "Pain.6921000": "pain",
    "Pain.80768000": "pain",
    "Pain.127949000": "pain",
    "Pain.720590003": "pain",
    "Pain.720591004": "pain",
    "Pain.74670003": "pain",
    "Pain.9736006": "pain",
    "Pain.5951000": "pain",
    "Pain.85562004": "pain",
    "Pain.78791008": "pain",
    "Pain.85151006": "pain",
    "Pain.29836001": "pain",
    "Pain.287579007": "pain",
    "Pain.287679003": "pain",
    "Pain.182281004": "pain",
    "Pain.62175007": "pain",
    "Pain.32153003": "pain",
    "Pain.72696002": "pain",
    "Pain.6757004": "pain",
    "Pain.82169009": "pain",
    "Pain.70258002": "pain",
    "Pain.6685009": "pain",
    "Pain.51636004": "pain",
    "Pain.56459004": "pain",
    "Pain.7769000": "pain",
    "Pain.22335008": "pain",
    "Pain.818983003": "pain",
    "Pain.289900009": "pain",
}

export const botflows_cards = {
    ...painPointsCards,
    "4869002": "caisr",
    "443450003": "asthma",
    "85354-9" : "bloodpressure",
    "22253000" : "pain",
    "29463-7" : "weight",
    "68130003" : "activity",
    "41950-7" : "steps",
    "8480-6": "sys",
    "8462-4": "dia",
    "goals": "goals",
    "mystats_about_you": "aboutyou",
    "mystats_habits": "smokingandalcohol",
    "mystats_sleep": "sleep",
    "mystats_blood_pressure_status": "hypertensionmedication"
}

let painPointKeys = {}
let painPointPanels = {}

Object.keys(painPointsCards).forEach(pain => {
    var obj = {};
    obj[pain] = pain;
    painPointKeys = {...painPointKeys, [pain]: pain}
    painPointPanels = {...painPointPanels, [pain]: 
        {
            name: pain,
            settings: {
                tabs: ['day', 'week', 'month', 'year']
            }
        }
    }
})

export const graphPanel = {
    ...painPointPanels,
    "443450003": {
        name: "asthma",
        settings: {
            tabs: ['day', 'week', 'month', 'year']
        }
    },
    "4869002": {
        name: "caisr",
        settings: {
            tabs: ['day', 'week']
        }
    },
    "85354-9": {
        name: "bloodpressure",
        settings: {
            tabs: ['day', 'week', 'month', 'year']
        }
    },
    "29463-7": {
        name: "weight",
        settings: {
            tabs: ['week', 'month', 'year']
        }
    },
    "41950-7": {
        name: "steps",
        settings: {
            tabs: ['week', 'month', 'year']
        }
    },
    "68130003": {
        name: "activity",
        settings: {
            tabs: ['week', 'month', 'year']
        }
    },
    "22253000": {
        name: "pain",
        settings: {
            tabs: ['day', 'week', 'month', 'year']
        }
    },
}

const TRANSLATIONS = {
    ...painPointKeys,
    "asthma": "443450003",
    "caisr": "4869002",
    "4869002": "4869002",
    "443450003": "443450003",
    "85354-9": "mystats_blood_pressure",
    "29463-7": "mystats_weight",
    "41950-7": "mystats_steps",
    "68130003": "mystats_physical_activity",
    "22253000": "mystats_pain",
    "systolic": "chart_systolic",
    "diastolic": "chart_diastolic",
    "weight": "mystats_weight",
    "steps": "mystats_steps",
    "activity": "mystats_physical_activity",
    "pain": "mystats_pain"
};

export const getChartTypeFromObservation = (observation) => {
    const chartType = observation?.code[0]?.code
    const translationId = chartType ? TRANSLATIONS[chartType] : null
    
    if (!translationId) {
        return ''
    }

    return translationId.toLowerCase().includes("pain.") ? 
    `${intl.formatMessage({ id: "settings_school_pain" })} - ${intl.formatMessage({ id: translationId })}` 
    : intl.formatMessage({ id: translationId })
}

export const getUnitFromObservation = (observation) =>  {
    let translationId = observation?.value[0]?.unit

    if (!translationId) {
        return ''
    }

    return intl.formatMessage({ id: translationId.toLowerCase() })
}

export const formatDetailsData = (data) => { 

    if (!data[0].containsObservations) {
      return []
    }

    let details = [];

    data.forEach((item) => {
        let max, min;
        if (item.containsObservations) {
            
          
        max = item.max.valueInt;
        min = item.min.valueInt;
                
        details.push({
            parameterName : item.parameterName,
            unit: item.avg.unit,
            avg: item.avg.valueInt,
            max: max,
            min: min,
        });
    }
    });

    let graphDetails = [];
    
    graphDetails.push({
        label: details[0].parameterName === "caisr" ? 
            `, ${intl.formatMessage({ id: "chart_average"})}` : 
            `${intl.formatMessage({ id: TRANSLATIONS[details[0].parameterName]})} ${intl.formatMessage({ id: "chart_average"})}`,
        value : details[0].avg,
        unit: details[0].unit && intl.formatMessage({ id: details[0].unit.toLowerCase() })
    });

    if (details.length > 1) {
        graphDetails.push({
            label: details[1].parameterName === "caisr" ? 
            `, ${intl.formatMessage({ id: "chart_average"})}` : 
            `${intl.formatMessage({ id: TRANSLATIONS[details[1].parameterName]})} ${intl.formatMessage({ id: "chart_average"})}`,
            value : details[1].avg,
            unit: details[1].unit && intl.formatMessage({ id: details[1].unit.toLowerCase() })
        });
    }

    graphDetails.push({
        label: details[0].parameterName === "caisr" ? 
            `, ${intl.formatMessage({ id: "chart_max"})}` : 
            `${intl.formatMessage({ id: TRANSLATIONS[details[0].parameterName]})} ${intl.formatMessage({ id: "chart_max"})}`,
        value : details[0].max,
        unit: details[0].unit && intl.formatMessage({ id: details[0].unit.toLowerCase() })
    });

    if (details.length > 1) {
        graphDetails.push({
            label: details[1].parameterName === "caisr" ? 
                `, ${intl.formatMessage({ id: "chart_max"})}` : 
                `${intl.formatMessage({ id: TRANSLATIONS[details[1].parameterName]})} ${intl.formatMessage({ id: "chart_max"})}`,
            value : details[1].max,
            unit: details[1].unit && intl.formatMessage({ id: details[1].unit.toLowerCase() })
        });
    }
    graphDetails.push({
        label: details[0].parameterName === "caisr" ? 
            `, ${intl.formatMessage({ id: "chart_min"})}` : 
            `${intl.formatMessage({ id: TRANSLATIONS[details[0].parameterName]})} ${intl.formatMessage({ id: "chart_min"})}`,
        value : details[0].min,
        unit: details[0].unit && intl.formatMessage({ id: details[0].unit.toLowerCase() })
    });

    if (details.length > 1) {
        graphDetails.push({
            label: details[1].parameterName === "caisr" ? 
            `, ${intl.formatMessage({ id: "chart_min"})}` : 
            `${intl.formatMessage({ id: TRANSLATIONS[details[1].parameterName]})} ${intl.formatMessage({ id: "chart_min"})}`,
            value : details[1].min,
            unit: details[1].unit && intl.formatMessage({ id: details[1].unit.toLowerCase() })
        });
    }
    return graphDetails;
}
export const formatGraphData = (columnData,graphdata,graphType) => {
    const data = [];
    data.push(columnData);

    graphdata.forEach(item => {
        if (item.hasAnyObservations) {
            if(graphType === "intervalGraph") {
                data.push([item.periodLabel,0,item.parameterObservations[1].valueInt,item.parameterObservations[0].valueInt]);
            } else {
                const value = item.parameterObservations[0].valueInt;
                data.push([item.periodLabel,0,value,value,value]);
            }           
        }
        else {
            if(graphType === "intervalGraph") {
                data.push([item.periodLabel,0,null,null]);
            } else {
                data.push([item.periodLabel,0,null,null,null]);
            } 
        }
    });
    return data;
  }
export const formatBloodpressureGraphData = (columnData,graphdata,graphType) => {
    const data = [];
    data.push(columnData);

    graphdata.forEach(item => {
        if (item.hasAnyObservations) {
            if(graphType === "intervalGraph") {
                data.push([item.periodLabel,0,item.parameterObservations[1].valueInt,item.parameterObservations[0].valueInt]);
            } else {

                const value1 = item.parameterObservations[0].valueInt;
                const value2 = item.parameterObservations[1].valueInt;
                data.push([item.periodLabel,0,value1,value2,null]);
            }           
        }
        else {
            if(graphType === "intervalGraph") {
                data.push([item.periodLabel,0,null,null]);
            } else {
                data.push([item.periodLabel,0,null,null,null]);
            } 
        }
    });
    return data;
  }
