import React from "react";
import routes, { ROUTE_TITLES } from "../routes/routes";
import OverviewService from "../service/OverviewService";
import { withRouter } from 'react-router-dom';
import { botflows_scenario } from "../botflows";
import { connect } from "react-redux";
import { setPageInformationAction } from "../redux/actions";
import { withOktaAuth } from "@okta/okta-react";
import { setUserGoals } from "../redux/actions";
import { OverviewComponent } from "./OverviewComponent";

class OverviewWrapper extends React.Component {
  service = new OverviewService();

  constructor(props) {
    super(props);
    this.getOnboardingFlow();

    this.state = {
      ready: false,
    }
  }
  
  getOnboardingFlowMeetings = async () => {
    const history = this.props.history;
      const pageInfo = {
        pageHeader: ROUTE_TITLES[routes.meetings],
        previousPath: "",
        observationCode: ""
      };
      this.props.setPageInformation(pageInfo);
      history.push(routes.meetings);
  }

  getOnboardingFlow = async () => {
    const { patientId } = this.props.userInfo;
    const history = this.props.history;
    const addCarePlan = sessionStorage.getItem('addCarePlan');
    const newMeeting = sessionStorage.getItem('gotomeeting');
    const careunit = sessionStorage.getItem('careunit');

    const currentCarePlans = await this.service.getPatientCarePlans();

    const anyMeetingCareplan = currentCarePlans.includes('meeting');
    const anyCaisrCareplan = currentCarePlans.includes('caisr') || currentCarePlans.includes('Caisr') || currentCarePlans.includes('CAISR');

    if(currentCarePlans.length === 1 && anyMeetingCareplan && !addCarePlan){
        let pageInfo = {
          pageHeader: ROUTE_TITLES[routes.meetings],
          previousPath: "",
          observationCode: ""
        };
        this.props.setPageInformation(pageInfo);

        if(careunit){
        // if patient is a registered meeting patient that needs to have care unit added
        localStorage.setItem("botTriggerAction", `${botflows_scenario.meeting_add_care_unit}`);

        pageInfo = { ...pageInfo,
          botTriggerAction: {
            scenario: botflows_scenario.meeting_add_care_unit,
          }
        }
        history.push(routes.addmeeting);
        
      } else {
        // if the patient is registered as a meeting patient only, send to meeting page
        history.push(routes.meetings);
      }

    } else if((addCarePlan?.toLowerCase() === 'caisr')){
      console.log("add anyCaisrCareplan")
      // if the patient needs to be registred to the caisr one
      localStorage.setItem("botTriggerAction", `${botflows_scenario.caisr_onboarding}`);
      sessionStorage.setItem("addCarePlan", 'caisr');

      const pageInfo = {
        pageHeader: ROUTE_TITLES[routes.caisr],
        previousPath: "",
        botTriggerAction: {
          scenario: botflows_scenario.caisr_onboarding,
        },
        observationCode: ""
      };

      this.props.setPageInformation(pageInfo);
      history.push(routes.addcaisr);

    } else if((addCarePlan === 'meeting') || (newMeeting && !addCarePlan)){
      // if the patient needs to be registred to the meeting one
      localStorage.setItem("botTriggerAction", `${botflows_scenario.meeting_onboarding}`);
      sessionStorage.setItem("addCarePlan", 'meeting');

      const pageInfo = {
        pageHeader: ROUTE_TITLES[routes.meetings],
        previousPath: "",
        botTriggerAction: {
          scenario: botflows_scenario.meeting_onboarding,
        },
        observationCode: ""
      };

      this.props.setPageInformation(pageInfo);
      history.push(routes.addmeeting);

    }  else if(anyCaisrCareplan){
      // send existing caisr patients to caisr page
      const pageInfo = {
        pageHeader: ROUTE_TITLES[routes.caisr],
        previousPath: "",
        observationCode: ""
      };

      this.props.setPageInformation(pageInfo);
      history.push(routes.caisr);

    } else {
      // else non-meeting patient that needs to go to overview
      this.service.getOnBoardingFlow(patientId).then((response) => {
        if (!anyMeetingCareplan && !response.hasCompletedOnboarding || addCarePlan != undefined || careunit){
          const history = this.props.history;
          const pageInfo = {
            pageHeader: ROUTE_TITLES[routes.report],
            previousPath: "",
            botTriggerAction: {
              scenario: botflows_scenario.onboarding,
            },
            observationCode: ""
          };
          this.props.setPageInformation(pageInfo);
          localStorage.setItem("botTriggerAction", `${botflows_scenario.onboarding}`);
          history.push(routes.report);
        } else {
          this.setState({ ready: true })
        }
      });
      
    }
  }

  render = () => (
    this.state.ready
      ? <OverviewComponent {...this.props} />
      : null
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
    setUserGoals: (goals) => dispatch(setUserGoals(goals)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOktaAuth(OverviewWrapper)));
