import React from "react";
import Icon from "./Icon";

const IconButton = ({ label, onClick, icon = "heart", active = false }) => (
  <div
    className={`iconButton${active === true ? "" : " inactive"}`}
    onClick={onClick}
  >
    <Icon type={icon} size="40" />
    <span className="label">{label}</span>
  </div>
);

export default IconButton;
