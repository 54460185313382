import React from "react";
import {Chart} from "react-google-charts";
import {Row, Col, Card} from "react-bootstrap";
import GraphDetails from "./GraphDetails";
import GraphService from "../service/GraphService";
import moment from 'moment-with-locales-es6';
import {environment} from "../environment";
import {
  graphPanel,
  formatDetailsData,
  formatGraphData,
  getChartTypeFromObservation,
  getUnitFromObservation
} from "../botflows";
import { injectIntl } from "react-intl";
import ChartNavigation from "./ChartNavigation";
import DetailsHistory from "./DetailsHistory";

class DayChart extends React.Component {
  dateFormat = 'YYYY-MM-DD';
  dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
  
  constructor(props) {
    super(props);

    this.service = new GraphService();

    this.state = {
      graphData: {},
      details: [],
      observation: null,
      allDetails: null,
      startTime: null,
      endTime: null
    }
  }

  componentDidMount() {
    const startDate = moment()
    const endDate = moment()

    this.getGraphData(startDate, endDate);
  }

  getGraphData = (startDate, endDate) => {
    this.setState({
      startTime: startDate.startOf('day').format(this.dateTimeFormat),
      endTime: endDate.endOf('day').format(this.dateTimeFormat)
    });

    this.service.getObservation(this.props.observationCode).then((response) => {
      if (response !== undefined) {
        const code = Object.keys(response)[0];
        this.setState({
          observation: response[code]
        });
      }
    });

    const params = {
      start: startDate.format(this.dateFormat),
      end: endDate.format(this.dateFormat),
      panel: graphPanel[this.props.observationCode].name,
      aggregation: "hour",
      culture: this.props.locale
    }

    this.service.getData(params).then((response) => {
      const details = formatDetailsData(response.parameterStatistics);
      const graphData = formatGraphData(this.props.columnData, response.periodParameterObservations, this.props.graphType);

      if (response !== undefined) {
        this.setState({
          graphData: graphData,
          details: details
        });
      }
    }).catch(e => console.log(e));
  }

  render() {
    const { intl } = this.props;
    let chartTitle =getChartTypeFromObservation(this.state.observation);

    return (
      <Card className="bp-card card-tab">
        <Card.Body>
          {(this.state.observation !== undefined || this.state.observation !== null) &&
            <>
              <Row>
                <Col xs={7}>
                  <h4 className="font-weight-bold">
                    {chartTitle === "caisr" ? intl.formatMessage({id: "hr_anxiety_level"}) : chartTitle}
                  </h4>
                </Col>
                <Col xs={5}>
                  <h2 className="text-right mb-0">
                    {this.state.observation?.value[0].value !== undefined ? `${parseInt(this.state.observation?.value[0].value)}` : ""}
                    {this.state.observation?.value.length > 1 ? `/${parseInt(this.state.observation?.value[1].value)}` : ""}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs={7}>
                  <small>{moment(this.state.observation?.effectiveDateTime).format(environment.dateFormat)}</small>
                </Col>
                <Col xs={5}>
                  <small className="float-right">{getUnitFromObservation(this.state.observation)}</small>
                </Col>
              </Row>
            </>
          }

          <ChartNavigation
            period="day"
            onNavigate={this.getGraphData}
            locale={this.props.locale}
            intl={intl}
          />

          {(this.state.graphData !== undefined && this.state.graphData.length > 0) &&
          <Row>
            <Col style={{backgroundColor: "#f2fbff"}} className="my-3">
              <Chart
                width={'100%'}
                height={'100%'}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={this.state.graphData}
                graph_id={Date.now().toString()}
                options={this.props.graphOptions}
                rootProps={{'data-testid': '1'}}
              />
            </Col>
          </Row>
          }
          <GraphDetails details={this.state.details} {...this.props}/>
          {(this.props.observationCode === "22253000" || 
            this.props.observationCode.includes("Pain.") || 
            this.props.observationCode.includes("443450003") || 
            this.props.observationCode === "4869002")  && 
            this.state.details.length > 0 && 
            <DetailsHistory 
              intl={intl}
              code={this.props.observationCode}
              start={this.state.startTime}
              end={this.state.endTime}
            />
          }
        </Card.Body>
      </Card>
    );
  }
}

export default injectIntl(DayChart);
