import React from "react";
import IconButton from "../IconButton";
import routes from "../../routes/routes";
import {connect} from "react-redux";
import {setPageInformationAction} from "../../redux/actions";
import { withRouter } from 'react-router-dom';
import { ROUTE_TITLES } from "../../routes/routes";
import MeetingService from "../../service/MeetingService";
import { botflows_scenario } from "../../botflows";
class Footer extends React.Component {
  service = new MeetingService();

  constructor(props) {
      super(props);
      this.state = {
          path: routes.overview,
          meetingPatient: null,
          caisrPatient: null,
          otherCareplansPatient: null
      }
  }

  async componentWillMount() {
    const patientCarePlans = await this.service.getPatientCarePlans();
    const patientCarePlansIncludesMeeting = patientCarePlans.includes('meeting');
    const patientCarePlansIncludesCaisr = patientCarePlans.includes('Caisr');

    if(patientCarePlans.length === 1){
      this.setState({ 
        meetingPatient: patientCarePlansIncludesMeeting,
        caisrPatient: patientCarePlansIncludesCaisr,
        otherCareplansPatient: !patientCarePlansIncludesMeeting
      })
    } else if(patientCarePlans.length > 1){
      this.setState({ 
        meetingPatient: patientCarePlansIncludesMeeting,
        caisrPatient: patientCarePlansIncludesCaisr,
        otherCareplansPatient: true
      })
    }
  }
  
  onNavigate = (path, header) => {
      let reloadPage = false;
      if(window.location.href.includes('/meeting/')){
        reloadPage = true;
      };
      const history = this.props.history;
      const pageInfo = {
        pageHeader: header,
        previousPath:"",
        botTriggerAction: "",
        observationCode: ""
      };
      this.props.setPageInformation(pageInfo);
      this.setState({ path });
      history.push(path);
      if(reloadPage){
        window.location.reload();
      }
  }

  on1177Click = () => {  
    const history = this.props.history;
    const pageInfo = {
          pageHeader: ROUTE_TITLES[routes.chat1177],
          previousPath: "",
          observationCode: "",
          botTriggerAction: {
            scenario: botflows_scenario["caisr_1177"]
          },
          hideSendBox: false
    };

    this.props.setPageInformation(pageInfo);

    localStorage.setItem("botTriggerAction", "caisr_1177");
    localStorage.setItem("pageHeader", ROUTE_TITLES[routes.chat1177]);

    history.push({
      pathname:  routes.chat1177
    });
  }

  render = () => {
    return(
      <footer className="page-footer">
        {this.state.otherCareplansPatient && !this.state.caisrPatient && <>
          <IconButton
            label={ROUTE_TITLES[routes.overview]}
            icon="overview"
            onClick = {() => {this.onNavigate(routes.overview, ROUTE_TITLES[routes.overview])}}
            active={this.state.path === routes.overview}
          />
          <IconButton
            label={ROUTE_TITLES[routes.mystats]}
            icon="mystats"
            onClick = {() => {this.onNavigate(routes.mystats, ROUTE_TITLES[routes.mystats])}}
            active={this.state.path === routes.mystats}
          />
          <IconButton
            label={ROUTE_TITLES[routes.facts]}
            icon="facts"
            onClick = {() => {this.onNavigate(routes.facts, ROUTE_TITLES[routes.facts])}}
            active={this.state.path === routes.facts || this.state.path === routes.article}
          />
        </>}
        {!this.state.caisrPatient && (this.state.meetingPatient || sessionStorage.getItem('gotomeeting')) && 
          <IconButton
            label={ROUTE_TITLES[routes.meetings]}
            icon="phone"
            onClick = {() => {this.onNavigate(routes.meetings, ROUTE_TITLES[routes.meetings])}}
            active={this.state.path === routes.meetings}
          />
        }
        {this.state.caisrPatient && 
        <>
          <IconButton
            label={String(ROUTE_TITLES[routes.caisr]).toUpperCase()}
            icon="mystats"
            onClick = {() => {this.onNavigate(routes.caisr, ROUTE_TITLES[routes.caisr])}}
            active={this.state.path === routes.caisr}
            />
            {/*
          <IconButton
            label={String(ROUTE_TITLES[routes.chat1177]).toUpperCase()}
            icon="mystats"
            onClick = {() => {this.on1177Click(routes.caisr1177, ROUTE_TITLES[routes.chat1177])}}
            active={this.state.path === routes.chat1177}
            />
        */}
        </>
        }
        {!this.state.caisrPatient && 
          <IconButton
            label={ROUTE_TITLES[routes.settings]}
            icon="settings"
            onClick = {() => {this.onNavigate(routes.settings, ROUTE_TITLES[routes.settings])}}
            active={this.state.path === routes.settings}
          />
        }
      </footer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}
export default connect(null,mapDispatchToProps)(withRouter(Footer));