import React from "react";

const IconJournal = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="icon_journal"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M15.5397882,22.1871766 L19.0090772,22.1871766 L19.0090772,18.8021955 L21.9909228,18.8021955 L21.9909228,22.1871766 L25.4629349,22.1871766 L25.4629349,25.0965599 L21.9909228,25.0965599 L21.9909228,28.481541 L19.0090772,28.481541 L19.0090772,25.0965599 L15.5397882,25.0965599 L15.5397882,22.1871766 Z M12.2720121,23.4040694 C12.2720121,27.838554 15.9564297,31.4334359 20.5013616,31.4334359 C25.0462935,31.4334359 28.730711,27.838554 28.730711,23.4040694 C28.730711,18.9695847 25.0462935,15.3773598 20.5013616,15.3773598 C15.9564297,15.3773598 12.2720121,18.9695847 12.2720121,23.4040694 L12.2720121,23.4040694 Z M9.32829047,8.49846175 L12.5007564,8.49846175 L12.5007564,10.1590687 L28.5019667,10.1590687 L28.5019667,8.49846175 L31.6717095,8.49846175 L31.6717095,36.9865054 L9.32829047,36.9865054 L9.32829047,8.49846175 Z M7,5.47748567 L7,39.5 L34,39.5 L34,5.47748567 L28.5019667,5.47748567 L28.5019667,4.72290589 L25.6916793,4.72290589 L25.6916793,1.5 L15.3110439,1.5 L15.3110439,4.72290589 L12.5007564,4.72290589 L12.5007564,5.47748567 L7,5.47748567 Z"
        id="Fill-1"
        fill="#069589"
      ></path>
    </g>
  </svg>
);

export default IconJournal;
