import React from "react";
import { injectIntl } from "react-intl";
import {withOktaAuth} from "@okta/okta-react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {setPageInformationAction} from "../redux/actions";
import StatisticsService from "../service/StatisticsService";

class Statistics extends React.Component {
    statisticsService = new StatisticsService();

    constructor(props) {
        super(props);
        this.state = {
            patients: null,
            headers: null,
            patientData: null,
            show: false
        }
    }

    componentDidMount() {
        this.getPatients();
    }

    getPatients = () => {
        this.statisticsService.getPatients().then(response => {
            let rows = response?.split(/(?:\r\n|\n)+/).filter(function(el) {return el.length !== 0});
            let headers = rows?.splice(0, 1)[0].split(",");

            let patientData = [];

            rows.forEach(item => {
                let itemArr = item.split(",");
                itemArr.pop();

                let patient = {};
                itemArr.forEach((td, index) => {
                    patient = {...patient, [headers[index]]: td}
                });
                patientData.push(patient)
            });

            this.setState({
                    patients: response && response?.length > 0 ? response : null,
                    headers, 
                    patientData
                })
        });
    }

    toggle = () => {
        this.setState({
            show: !this.state.show
        })
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.state.patients);
    }
    

    render = () => {
        return (<>
            {this.state.patients && 
            <>
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <div className="d-flex justify-content-between">
                                <h5 className="mb-0">
                                    <button className="btn" onClick={this.toggle}>
                                        View raw
                                    </button>
                                </h5>
                                <h5 className="mb-0">
                                    <button className="btn btn-outline-secondary" onClick={this.copyToClipboard}>
                                        Copy raw to clipboard
                                    </button>
                                </h5>
                            </div>
                        </div>

                        <div className={`collapse${this.state.show ? " show" : ""}`} aria-labelledby="headingOne">
                            <div className="card-body">
                                <pre>{this.state.patients}</pre>
                            </div>
                        </div>
                    </div>
                </div>

                <table className="table table-bordered table-striped">
                    <thead className="">
                        {this.state.headers && <tr>
                                <th scope="col">{this.state.headers[0]} - Total patients: {this.state.patientData.length}</th>
                                <th scope="col">{this.state.headers[1]}</th>
                                <th scope="col">{this.state.headers[2]}</th>
                                <th scope="col">{this.state.headers[3]}</th>
                                <th scope="col">{this.state.headers[5]}</th>
                                <th scope="col">{this.state.headers[4]}</th>
                        </tr>}
                    </thead>
                    <tbody>
                        
                    {this.state.patientData && this.state.patientData?.map((row, index) => {
                        return <tr key={row[this.state.headers[0]]}>
                            <td scope="row">{row[this.state.headers[0]]}</td>
                            <td>{row[this.state.headers[1]]}</td>
                            <td>{row[this.state.headers[2]]}</td>
                            <td>{row[this.state.headers[3]] ? row[this.state.headers[3]].replaceAll(";",", ") : ""}</td>
                            <td>{row[this.state.headers[5]] ? row[this.state.headers[5]].replaceAll(";",", ") : ""}</td>
                            <td>{row[this.state.headers[4]]}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </>
            }
        </>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
    };
}

export default connect(null, mapDispatchToProps)(withOktaAuth(withRouter(injectIntl(Statistics))));