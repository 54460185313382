import React from "react";

const IconUser = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="icon_user"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M20.4963353,23 C16.3178082,23.0114003 13.0148876,19.5202614 13,15.0750592 C12.9779422,10.59439 16.3251973,6.99645592 20.5037244,7 C24.6379169,7.00827826 27.9888665,10.5664104 28,14.968264 C28.0147283,19.4847944 24.7413641,22.9881497 20.4963353,23"
        id="Fill-4"
        fill="#069589"
      ></path>
      <path
        d="M34.4568126,30.1085825 C38.6521524,24.9629515 39.511667,14.6716893 32.6544825,7.58431068 C26.0547738,0.764893204 15.3051621,0.613825243 8.57671553,7.25071845 C1.39011359,14.3415922 2.16254078,24.7843107 6.55856019,30.2794563 C6.75166699,29.8833398 6.93341456,29.5221748 7.10758932,29.1575146 C8.55021068,26.120233 10.8674922,24.1008155 13.9874922,23.0949903 C14.5062301,22.9283883 15.2407932,23.0410097 15.740599,23.3012039 C18.5993369,24.7885825 21.552735,25.0949903 24.3963272,23.5843107 C25.9336087,22.769165 27.1641913,22.9128544 28.4553563,23.728 C29.595065,24.4507184 30.7991427,25.1726602 31.7003078,26.159068 C32.7756476,27.335767 33.559434,28.7959612 34.4568126,30.1085825 M20.4357447,0.104678487 C31.2989485,0.12023301 39.9130262,8.928 39.8979005,20.0076117 C39.882735,31.2697476 31.2951621,40.0231456 20.2880748,40.0002779 C9.55739515,39.9765437 0.966035922,31.0837282 1.00001257,20.0351845 C1.03797767,8.8856699 9.61419126,0.0891650485 20.4357447,0.104678487"
        id="Fill-1"
        fill="#069589"
      ></path>
    </g>
  </svg>
);

export default IconUser;
