import React from "react";
import {Row, Col, Card} from "react-bootstrap";
import { injectIntl } from "react-intl";
import {withOktaAuth} from "@okta/okta-react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import routes, {ROUTE_TITLES} from "../routes/routes";
import {botflows_cards, botflows_scenario} from "../botflows";
import {setPageInformationAction} from "../redux/actions";
import ArticleService from "../service/ArticleService";
import MyStatsService from "../service/MyStatsService";
import MeetingService from "../service/MeetingService";
import TextTruncate from "react-text-truncate";
import intlConfig from "../lang/intlConfig";
import { localizations } from "../lang/localization";
import CreateReportModal from "./CreateReportModal";

class Settings extends React.Component {
    articleService = new ArticleService();
    myStatsService = new MyStatsService();
    meetingService = new MeetingService();

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.locale,
            connectionState: null,
            meetingPatient: null,
            otherCareplansPatient: null
        }
    }

    logout = async () => {
        const appDomain = window.location.origin;
        this.props.oktaAuth.tokenManager.clear();
        sessionStorage.removeItem('gotomeeting')
        await this.props.oktaAuth.signOut();
        window.location.replace(appDomain);
    }

    handleLangSelectChange = (e) => {
        localStorage.setItem("lang", e.target.value);
        window.location.reload();
    }

    goToTermsOfService = () => {
        const { history } = this.props;
        history.push("/conditions");
    }

    async componentWillMount() {
        await this.getCarePlans();
    }

    componentDidMount() {
        this.getArticle();
        this.getWithingsStatus();
    }

    getWithingsStatus = () => {
        this.myStatsService.getSubscriptions().then(response => {
            this.setState(
                { 
                    connectionState: response && response?.length > 0,
                });
        });
    }

    getCarePlans = async () => {
        await this.myStatsService.getPatientCarePlans().then(response => {
            if(response.length === 1){
              this.setState({ 
                meetingPatient: response.includes('meeting'),
                otherCareplansPatient: !response.includes('meeting'),
                carePlans: response,
                includePainTracker: response.filter(item => item.toLowerCase().includes("pain")).length > 0
              })
            } else if(response.length > 1){
              this.setState({ 
                meetingPatient: response.includes('meeting'),
                otherCareplansPatient: true,
                carePlans: response,
                includePainTracker: response.filter(item => item.toLowerCase().includes("pain")).length > 0
              })
            }
        });
    }

    getArticle = () => {
        const articleId = `guideline_${intlConfig.locale}`;
        this.articleService.getArticle(articleId).then((response) => {
            this.setState({ article: response });
        });
    }

    openPainSelection = () => {
        const history = this.props.history;
        const scenario = "pain_selector";

        const pageInfo = {
          pageHeader: ROUTE_TITLES[routes.report],
          previousPath: this.props.previousPath,
          botTriggerAction: {
            scenario,
            isRedirect: true
          },
          observationCode: ''
        };

        this.props.setPageInformation(pageInfo);

        localStorage.setItem("botTriggerAction", scenario);
        localStorage.setItem("pageHeader", ROUTE_TITLES[routes.report]);

        history.push({ pathname: routes.tracks, state: { id: '' } });
    }

    openTrackSelection = () => {
        const history = this.props.history;
        const scenario = botflows_scenario['settings_careplan'];

        const pageInfo = {
          pageHeader: ROUTE_TITLES[routes.report],
          previousPath: this.props.previousPath,
          botTriggerAction: {
            scenario,
            isRedirect: true
          },
          observationCode: ''
        };

        this.props.setPageInformation(pageInfo);

        localStorage.setItem("botTriggerAction", scenario);
        localStorage.setItem("pageHeader", ROUTE_TITLES[routes.report]);

        history.push({ pathname: routes.tracks, state: { id: '' } });
    }

    openArticle = (articleId) => {
        const history = this.props.history;
        const pageInfo = {
            pageHeader: ROUTE_TITLES[routes.article],
            previousPath: routes.facts,
            botTriggerAction: "",
            observationCode: ""
        };
        this.props.setPageInformation(pageInfo);
        history.push({pathname: `${routes.article}/${articleId}`});
    }

    goToGoalSetting = () => {
        const history = this.props.history;
        const pageInfo = {
            pageHeader: ROUTE_TITLES[routes.report],
            previousPath: this.props.previousPath,
            botTriggerAction: {
                scenario: botflows_scenario["report"],
                card: botflows_cards["goals"]
            },
            observationCode: "goals"
        };
        this.props.setPageInformation(pageInfo);
        localStorage.setItem("botTriggerAction", `${botflows_scenario["report"]}#${botflows_cards["goals"]}`);
        localStorage.setItem("pageHeader", ROUTE_TITLES[routes.report]);
        history.push({pathname: "/report", state: { id: "goals" }});
    }

    goToWithingsSetting = () => {
        const history = this.props.history;
        const pageInfo = {
            pageHeader: ROUTE_TITLES[routes.withings],
            previousPath: this.props.previousPath,
            observationCode: "withings"
        };
        this.props.setPageInformation(pageInfo);
        localStorage.setItem("pageHeader", ROUTE_TITLES[routes.withings]);
        history.push({pathname: "/withings", state: { id: '' }});
    }

    createReportModal = () => {
        if (typeof this.props.setModal !== 'function') {
            return;
        }
        this.props.setModal({
            content: (
                <CreateReportModal
                    carePlans={this.state.carePlans}
                    myService={this.myStatsService}
                    setModal={this.props.setModal}
                />
            )
        });
    }

    render = () => {
        const { intl } = this.props;

        return (<>
            <Row>
                {this.state.otherCareplansPatient  &&
                <Col md={6} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <h5 className="font-weight-bold text-uppercase mb-0">{intl.formatMessage({ id: "settings_school" })}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                <button
                                    className="register-button ml-n2 mt-3"
                                    onClick={() => this.openTrackSelection()}
                                >{intl.formatMessage({ id: "settings_select_schools" })}</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>}

                <Col md={6} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <h5 className="font-weight-bold text-uppercase mb-3">{intl.formatMessage({ id: "settings_language" })}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <select value={this.state.language} onChange={this.handleLangSelectChange} className="lan-dropdown form-control">
                                        {localizations.map((localization)=> {
                                            return (
                                                <option
                                                    value={localization.languageCode}
                                                    key={localization.languageCode}
                                                >{localization.name}</option>
                                            )
                                        })}
                                    </select>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {this.state.otherCareplansPatient && <>
                <Col md={6} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <h5 className="font-weight-bold text-uppercase mb-0">{intl.formatMessage({ id: "goals_mygoals" })}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <button
                                      className="register-button mt-3"
                                      onClick={this.goToGoalSetting}
                                    >{intl.formatMessage({ id: "settings_mygoals_click_set" })}</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {this?.state?.carePlans && this?.state?.includePainTracker &&
                    <Col md={6} className="d-flex px-0 px-md-4">
                        <Card className="bp-card mb-2 mb-md-5">
                            <Card.Body>
                                <Row>
                                    <Col xs={12} className="d-flex justify-content-center">
                                        <h5 className="font-weight-bold text-uppercase mb-0">{intl.formatMessage({ id: "pain_tracker" })}</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="d-flex justify-content-center">
                                        <button
                                        className="register-button mt-3"
                                        onClick={this.openPainSelection}
                                        >{intl.formatMessage({ id: "settings_select_schools" })}</button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                }

                <Col md={6} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <h5 className="font-weight-bold text-uppercase mb-0">{intl.formatMessage({ id: "settings_report" })}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <button
                                      className="register-button mt-3"
                                      onClick={this.createReportModal}
                                    >{intl.formatMessage({ id: "settings_report_create" })}</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <h5 className="font-weight-bold text-uppercase mb-0">{intl.formatMessage({ id: "withings_withingssubscription" })}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <button
                                      className="register-button mt-3"
                                      onClick={this.goToWithingsSetting}
                                    >
                                        {intl.formatMessage({ id: this.state.connectionState 
                                            ? "settings_withings_connected" 
                                            : "settings_withings_click_set" })}
                                    </button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                </>}

                <Col md={6} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <h5 className="font-weight-bold text-uppercase mb-0">{intl.formatMessage({ id: "settings_logout" })}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <button
                                      className="register-button mt-3"
                                      onClick={this.logout}
                                    >{intl.formatMessage({ id: "settings_logout_click_exit" })}</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {this.state.otherCareplansPatient && this.state?.article?.fullArticle && (
                  <Col md={6} className="d-flex flex-column mb-2 mb-md-5 px-0 px-md-4">
                      <Card className="bp-card">
                          <Card.Body
                            className="text-center"
                            onClick={() => { this.openArticle(this.state?.article?.fullArticle?.id) }}
                          >
                              <div className="article-wrapper position-relative">
                                  <h3 className="overview-article-header">
                                      <TextTruncate
                                        line={2}
                                        truncateText={"..."}
                                        text={this.state?.article?.fullArticle?.title}
                                      />
                                  </h3>
                                  <div className="overview-article-subheader text-left mb-0">
                                      <TextTruncate
                                        line={3}
                                        truncateText={"..."}
                                        text={this.state?.article?.fullArticle?.subtitle}
                                      />
                                  </div>
                                  <img className="card-image" aria-hidden="false" alt="Login" src={this.state?.article?.fullArticle?.thumbnailUri}/>
                              </div>
                          </Card.Body>
                      </Card>
                  </Col>
                )}
            </Row>

            <Row>
                <Col className="text-center">
                    <button className="btn btn-link" onClick={this.goToTermsOfService}>
                        {intl.formatMessage({ id: "settings_TOS" })}
                    </button>
                </Col>
            </Row>
        </>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
    };
}

export default connect(null, mapDispatchToProps)(withOktaAuth(withRouter(injectIntl(Settings))));
