import ApiService from "./ApiService";

export default class MeetingService {  
    getMeetingRequirements = async () => {
        return this.apiCall({
            method: "get",
            apiMethod: "acs/meetingRequirements",
            data: null
        });
    }

    getPatientMeetings = async () => {
        return this.apiCall({
            method: "get",
            apiMethod: "meeting/getpatientmeetings",
            data: null
        });
    }

    addPatientToMeeting = async (meetingId) => {
        return this.apiCall({
            method: "post",
            apiMethod:  `meeting/addpatienttomeeting?meetingId=${meetingId}`,
            data: null
        });
    }

    getPatientCarePlans = async() => {
        return this.apiCall({
            method: "get",
            apiMethod: "careplan",
            data: null,
            params: null
        })
    }

    apiCall = async (options) => {
        const service = new ApiService();
        const response = await service.apiCall(options);
        if(response.data !== null && response.data !== undefined) {
            return response.data;
        }
        return null;
    }
}
