import React from "react";
import {Tabs, Tab, Row, Col} from "react-bootstrap";
import {injectIntl} from "react-intl";
import DayChart from "./DayChart";
import WeekChart from "./WeekChart";
import MonthChart from "./MonthChart";
import YearChart from "./YearChart";
import {graphPanel} from "../botflows";

const tabCharts = {
  day: DayChart,
  week: WeekChart,
  month: MonthChart,
  year: YearChart
}

class Details extends React.Component {
  observationCode = this.props.location?.state?.id;

  constructor(props) {
    super(props);
    const settings = this.getGraphIntialSettings();
    this.state = {
      tabKey: "week",
      graphType: settings.graphType,
      graphOptions: settings.graphOptions,
      columnData: settings.columnData
    }
  }

  getGraphType = () => {
    switch (this.observationCode) {
      case "85354-9":
        return "intervalGraph";
      default:
        return "lineGraph";
    }
  }


  getGraphIntialSettings = () => {
    let graphType, graphOptions, columnData;

    graphType = this.getGraphType();

    if (graphType === "intervalGraph") {
      graphOptions = {
        title: '',
        curveType: 'function',
        intervals: {color: 'series-color'},
        lineWidth: 0,
        interval: {
          i0: {
            color: '#4374E0',
            style: 'bars',
            barWidth: 0,
            lineWidth: 2,
            pointSize: 5,
            fillOpacity: 1,
          },
          i1: {
            color: '#E49307',
            style: 'bars',
            barWidth: 0,
            lineWidth: 2,
            pointSize: 5,
            fillOpacity: 1,
          },
        },
        legend: 'none',
        backgroundColor: "#f2fbff",
        chartArea: {
          width: "90%",
          right: 0,
        }
      };
      columnData = [
        {type: 'string', label: 'x'},
        {type: 'number', label: 'values'},
        {id: 'i0', type: 'number', role: 'interval'},
        {id: 'i1', type: 'number', role: 'interval'},
      ];
    } else {
      graphOptions = {
        title: '',
        // curveType: 'function',
        intervals: {color: 'series-color'},
        lineWidth: 0,
        interval: {
          i0: {
            color: 'green',
            style: 'bars',
            barWidth: 0,
            lineWidth: 2,
            pointSize: 8,
            fillOpacity: 1,
          },
          i1: {
            color: 'green',
            style: 'bars',
            barWidth: 0,
            lineWidth: 2,
            pointSize: 8,
            fillOpacity: 1,
          },
          i2: {style: 'line', curveType: 'none', fillOpacity: 0.3, color: 'grey', lineWidth: 3},
        },
        legend: 'none',
        interpolateNulls: true,
        backgroundColor: "#f2fbff",
        chartArea: {
          width: "90%",
          right: 0,
        }
      };
      columnData = [
        {type: 'string', label: 'x'},
        {type: 'number', label: 'values'},
        {id: 'i0', type: 'number', role: 'interval'},
        {id: 'i1', type: 'number', role: 'interval'},
        {id: 'i2', type: 'number', role: 'interval'}
      ]
    }
    if(
      this.observationCode === "22253000" || 
      this.observationCode?.includes("Pain.") ||
      this.observationCode?.includes("443450003") ||
      this.observationCode === "4869002"){
      graphOptions = {...graphOptions,
        vAxis: {
          viewWindow: {
              max: this.observationCode === "443450003" ? 27 : 12,
              min: 0,
          }
        },
        interval: {
          i0: {
            color: 'green',
            style: 'bars',
            barWidth: 0,
            lineWidth: 2,
            pointSize: 8,
            fillOpacity: 1,
          },
          i1: {
            color: 'green',
            style: 'bars',
            barWidth: 0,
            lineWidth: 2,
            pointSize: 8,
            fillOpacity: 1,
          },
          i2: {style: 'line', curveType: 'none', fillOpacity: 0.3, color: 'grey', lineWidth: 0},
        }
      }
    }

    return {graphType: graphType, graphOptions: graphOptions, columnData: columnData};
  }

  handleSelect = (key) => {
    this.setState({tabKey: key});
  }

  render = () => {
    const { intl } = this.props;

    if (!this.observationCode) {
      return null
    }

    const panelConfig = graphPanel[this.observationCode]

    return (
      <Row>
        <Col className="px-0 px-md-4">
          <Tabs
            defaultActiveKey="week"
            id="uncontrolled-tab-example"
            className="details-tab"
            onSelect={this.handleSelect}
          >
            {panelConfig.settings.tabs.map((tab) => {
              const Chart = tabCharts[tab]

              return (
                <Tab eventKey={tab} title={intl.formatMessage({ id: `chart_${tab}` })} key={tab}>
                  {this.observationCode !== undefined && this.state.tabKey === tab && 
                  <Chart
                    graphOptions={this.state.graphOptions}
                    columnData={this.state.columnData}
                    observationCode={this.observationCode}
                    graphType={this.state.graphType}
                    {...this.props}
                  />
                  }
                </Tab>
              )
            })}
          </Tabs>
        </Col>
      </Row>
    );
  }
}

export default injectIntl(Details);
