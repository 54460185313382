import React from "react";
import {Row, Col, Card} from "react-bootstrap";
import {injectIntl} from "react-intl";
import { environment } from "../environment";
import WithingsService from "../service/WithingsService";
import queryString from 'query-string';

class Withings extends React.Component {
  observationCode = this.props.location?.state?.id;
  service = new WithingsService();

  constructor(props) {
    super(props);
    this.state = {
        language: this.props.locale,
        code: queryString.parse(this.props.location.search).code,
        responseCode: "",
        activeSubscriptions: [],
        revokeConfirm: false,
        loading: true,
        reset: false,
        errorMessage: ""
    }
  }

  componentDidMount() {
    this.withingsListSubscriptions();
  }

  getUriForWithingsApproval = () => {
    return "https://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=" + 
        environment.withingsClientId +
        "&redirect_uri=" +
        environment.withingsRedirectUri +
        "&state=abcdefg&scope=user.activity,user.metrics";
  }

  getApprovalCode = () => {
    let params = queryString.parse(this.props.location.search);
    this.setState({code: params.code});
    return params.code;
  }

  withingsSubscribe = () => {
    let params = queryString.parse(this.props.location.search);

    if (params.code !== undefined && params.code !== null) {
        this.setState({loading: true});

        this.service.subscribe(params.code).then((response) => {
            let responseCode = JSON.stringify(response);
            this.setState({responseCode: responseCode});
            this.withingsListSubscriptions();
        }).catch((error) => {
            let errormessage = JSON.stringify(error);
            this.setState({errorMessage: errormessage});
            this.setState({loading: false});
        });
    }
  }

  withingsListSubscriptions = () => {
    this.service.getSubscriptions().then((response) => {
        if(response) {
            let subscriptions = JSON.stringify(response);
            this.setState({activeSubscriptions: subscriptions});
        } else {
            this.setState({activeSubscriptions: []});
        }
        this.setState({loading: false});
    }).catch((error) => {
        console.log(error);
        let errormessage = JSON.stringify(error);
        this.setState({errorMessage: errormessage});
        this.setState({loading: false});
    });
  }

  withingsRevokeSubscriptions = () => {
    this.setState({revokeConfirm: true});
    this.setState({responseCode: ""});
  }

  withingsRevokeSubscriptionsCancel = () => {
    this.setState({revokeConfirm: false});
  }
  
  withingsRevokeSubscriptionsConfirmed = () => {
    this.service.revokeSubscriptions().then(() => {
        this.setState({activeSubscriptions: []});
        this.setState({revokeConfirm: false});
        this.setState({reset: true});
    }).catch((error) => {
        let errormessage = JSON.stringify(error);
        this.setState({errorMessage: errormessage});
    });
  }

  withingsGoBackToSettings = () => {
    window.location.href = '/settings';
  }

  render = () => {
    const divStyle = {
        textAlign: 'center'
    }

    const titleStyle = {
        textAlign: 'center'
    }

    const buttonStyle = {
        borderWidth: '1px',
        height: '34px',
        outline: 'none'
    }

    const buttonTextStyle = {
        fontSize: '0.75rem',
        fontWeight: '600'
    }

    const listStyle = {
        marginBottom: '0'
    }

    const horizontalLineStyle = {
        height: '8px',
        overflow: 'hidden',
        marginRight: '0px',
        marginLeft: '0px',
        flex: '0 0 auto'
    }

    const { intl } = this.props;
    const approvalCode = this.state.code;
    const responseCode = this.state.responseCode;
    const errorMessage = this.state.errorMessage;
    const subscriptions = this.state.activeSubscriptions;
    const revokeConfirm = this.state.revokeConfirm;
    const loading = this.state.loading;
    const reset = this.state.reset;

    let flowState = "Loading";

    if(loading) {
        flowState = "Loading";
    }
    else if(subscriptions && subscriptions !== "null" && subscriptions.length > 0 && revokeConfirm === true) {
        flowState = "RevokeSubscription";
    }
    else if(subscriptions && subscriptions !== "null" && subscriptions.length > 0) {
        flowState = "ActiveSubscription";
    }
    else if(!errorMessage && approvalCode && approvalCode !== "" && !reset)
    {
        flowState = "Subscribe";
    }
    else
    {
        flowState = "Welcome";
    }


    let visiblePanel;
    if(flowState === "Loading") {
        visiblePanel = 
        <Col>
            <div className="mb-0">
                <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                <div className="d-flex justify-content-center" style={divStyle}>{intl.formatMessage({ id: "settings_withings_loading_text" })}</div>
            </div>
        </Col>
    } else if(flowState === "RevokeSubscription") {
        visiblePanel = 
        <Col>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <div className="mb-0">
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                        <div className="d-flex justify-content-center" style={divStyle}>{intl.formatMessage({ id: "settings_withings_revoke_subscription_text_1" })}</div>
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                        <div className="d-flex justify-content-center" style={divStyle}>{intl.formatMessage({ id: "settings_withings_revoke_subscription_text_2" })}</div>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={8} className="d-flex justify-content-around">
                    <button className="register-button mt-3" style={buttonStyle} onClick={this.withingsRevokeSubscriptionsConfirmed}> <div style={buttonTextStyle}> {intl.formatMessage({ id: "settings_withings_revoke_subscription_button_confirm" })} </div></button>
                    <button className="register-button mt-3" style={buttonStyle} onClick={this.withingsRevokeSubscriptionsCancel}> <div style={buttonTextStyle}> {intl.formatMessage({ id: "settings_withings_revoke_subscription_button_cancel" })} </div></button>
                </Col>
            </Row>
        </Col>
    } else if(flowState === "ActiveSubscription") {
        visiblePanel = 
        <Col>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <div className="mb-0">
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                        <div className="d-flex justify-content-center" style={divStyle}>{intl.formatMessage({ id: "settings_withings_already_activated_text_1" })}</div>
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                        <div className="d-flex justify-content-center" style={divStyle}>{intl.formatMessage({ id: "settings_withings_already_activated_text_2" })}</div></div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={8} className="d-flex justify-content-around">
                    <button className="register-button mt-3" style={buttonStyle} onClick={this.withingsRevokeSubscriptions}>{intl.formatMessage({ id: "settings_withings_already_activated_button" })}</button>
                    <button className="register-button mt-3" style={buttonStyle} onClick={this.withingsGoBackToSettings}> <div style={buttonTextStyle}> {intl.formatMessage({ id: "settings_withings_button_go_back" })} </div></button>
                </Col>
            </Row>
        </Col>
    } else if(flowState === "Subscribe") {
        visiblePanel = 
        <Col>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <div className="mb-0">
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                        <div style={divStyle}> {intl.formatMessage({ id: "settings_withings_subscribe_text_1" })} </div>
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <div className="mb-0">
                        <ul style={listStyle}>
                            <li>{intl.formatMessage({ id: "settings_withings_subscribe_text_blood_pressure" })}</li>
                            <li>{intl.formatMessage({ id: "settings_withings_subscribe_text_weight" })}</li>
                            <li>{intl.formatMessage({ id: "settings_withings_subscribe_text_height" })}</li>
                            <li>{intl.formatMessage({ id: "settings_withings_subscribe_text_steps" })}</li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <button className="register-button mt-3" style={buttonStyle} onClick={this.withingsSubscribe}> <div style={buttonTextStyle}> {intl.formatMessage({ id: "settings_withings_subscribe_text_button_confirm" })} </div></button>
                </Col>
            </Row>
        </Col>
    } else if(flowState === "Welcome") {
        visiblePanel = 
        <Col>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <div className="mb-0">
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                        <div className="d-flex justify-content-center" style={divStyle}>{intl.formatMessage({ id: "settings_withings_welcome_text_1" })}</div>
                        <div class="ac-horizontal-separator" style={horizontalLineStyle}></div>
                        <div className="d-flex justify-content-center" style={divStyle}>{intl.formatMessage({ id: "settings_withings_welcome_text_2" })}</div>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={8} className="d-flex justify-content-around">
                    <a className="btn register-button mt-3" style={buttonStyle} 
                        href={this.getUriForWithingsApproval()}>{intl.formatMessage({ id: "settings_withings_welcome_button" })}</a>
                        <button className="register-button mt-3" style={buttonStyle} onClick={this.withingsGoBackToSettings}> <div style={buttonTextStyle}> {intl.formatMessage({ id: "settings_withings_button_go_back" })} </div></button>
                </Col>
            </Row>
        </Col>
    }
    
    let resultPanel;
    if(errorMessage) {
        resultPanel =   <div className="mb-0">
                            <hr/>
                            <div className="d-flex justify-content-center" style={divStyle} >{intl.formatMessage({ id: "settings_withings_connection_failure" })}</div>
                        </div>
    } else {
        resultPanel = <div>
                        {responseCode ? (
                                <div className="mb-0" style={divStyle} >
                                    <hr/>
                                    <div className="d-flex justify-content-center" style={divStyle} >{intl.formatMessage({ id: "settings_withings_connection_success" })}</div>
                                </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
    }

    return (
        <Row>
            <Col md={12} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <div className="font-weight-bold text-uppercase mb-0"  style={titleStyle} >{intl.formatMessage({ id: "withings_withingssubscription" })}</div>
                                </Col>
                            </Row>
                            <Row>
                                {visiblePanel}
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    {resultPanel}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
        </Row>
    );
  }
}

export default injectIntl(Withings);
