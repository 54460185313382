import React from "react";

const IconNavHeartActive = () => (
  <svg
    width="62px"
    height="62px"
    viewBox="0 0 62 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="NavBar" transform="translate(-149.000000, -15.000000)">
        <g id="navicon_heartrate" transform="translate(150.000000, 16.000000)">
          <path
            d="M29.937425,0.499925 C13.705175,0.499925 0.499925,13.705175 0.499925,29.937425 C0.499925,46.169675 13.705175,59.374925 29.937425,59.374925 C46.169675,59.374925 59.374925,46.169675 59.374925,29.937425 C59.374925,13.705175 46.169675,0.499925 29.937425,0.499925"
            id="Path"
            fill="#FFFFFF"
          ></path>
          <path
            d="M46.2107,17.3273 L46.27595,17.1968 L59.2262,26.9828 C59.22695,26.9888 59.22695,26.9948 59.2277,27.0008 C57.74945,12.14255 45.1787,0.49955 29.9372,0.49955 C13.7057,0.49955 0.4997,13.70555 0.4997,29.93705 C0.4997,46.1693 13.7057,59.37455 29.9372,59.37455 C32.4632,59.37455 34.91045,59.0438 37.2512,58.4423 L30.04595,49.8878 C30.8222,49.51505 33.6992,47.89355 37.12595,45.1778 C40.37345,42.6053 44.74595,38.51705 47.34095,33.5453 L48.24845,31.57955 C49.14095,29.37455 49.59095,27.1628 49.59095,25.00205 C49.59095,21.9953 48.4742,19.4303 46.27595,17.3828 C46.25495,17.36255 46.2317,17.3468 46.2107,17.3273"
            id="Fill-1"
            fill="#069388"
          ></path>
          <path
            d="M30.417125,52.21625 C30.446375,52.229 30.482375,52.24475 30.499625,52.25"
            id="Fill-3"
            fill="#000000"
          ></path>
          <path
            d="M29.93375,30.874925 L29.93375,32.839925 L27.81875,32.839925 L26.37875,28.024925 L23.59625,44.727425 L19.71125,30.274925 L19.10375,32.839925 L12.17375,32.839925 C11.87375,32.194925 11.57375,31.609925 11.34875,30.942425 L17.34125,30.874925 L19.87625,23.397425 L23.19125,35.569925 L25.80875,19.842425 L29.25875,30.874925 L29.93375,30.874925 Z"
            id="Fill-5"
            fill="#F2F2F2"
          ></path>
          <polygon
            id="Fill-7"
            fill="#B3B3B3"
            points="48.248675 31.57985 47.341175 33.54485 41.506175 33.54485 39.983675 29.20985 36.751175 43.54235 33.083675 32.83985 29.933675 32.83985 29.933675 30.87485 34.486175 30.87485 36.406175 36.71735 39.488675 22.87985 42.826175 31.57985"
          ></polygon>
          <path
            d="M49.59125,25.00235 C49.59125,27.16235 49.14125,29.37485 48.24875,31.57985 L42.82625,31.57985 L39.48875,22.87985 L36.40625,36.71735 L34.48625,30.87485 L29.93375,30.87485 L29.93375,20.14985 L30.17375,19.62485 C31.76375,16.13735 34.49375,14.43485 38.53625,14.43485 C41.55875,14.43485 44.16125,15.42485 46.27625,17.38235 C48.47375,19.42985 49.59125,21.99485 49.59125,25.00235"
            id="Fill-9"
            fill="#F7931E"
          ></path>
          <path
            d="M47.34125,33.545 C44.74625,38.5175 40.37375,42.605 37.12625,45.1775 C33.51125,48.0425 30.50375,49.6925 29.93375,49.94 L29.93375,32.84 L33.08375,32.84 L36.75125,43.5425 L39.98375,29.21 L41.50625,33.545 L47.34125,33.545 Z"
            id="Fill-11"
            fill="#F7931E"
          ></path>
          <path
            d="M29.93375,32.84 L29.93375,49.94 C29.34125,49.685 26.20625,47.96 22.47875,44.9675 C19.12625,42.275 14.65625,38.0075 12.17375,32.84 L19.10375,32.84 L19.71125,30.275 L23.59625,44.7275 L26.37875,28.025 L27.81875,32.84 L29.93375,32.84 Z"
            id="Fill-13"
            fill="#FBB03B"
          ></path>
          <path
            d="M29.93375,20.150075 L29.93375,30.875075 L29.25875,30.875075 L25.80875,19.842575 L23.19125,35.570075 L19.87625,23.397575 L17.34125,30.942575 L11.34875,30.942575 C10.63625,28.910075 10.28375,26.930075 10.28375,25.002575 C10.28375,21.995075 11.39375,19.430075 13.59875,17.382575 C15.70625,15.425075 18.30875,14.435075 21.33875,14.435075 C25.37375,14.435075 28.11125,16.137575 29.69375,19.625075 L29.93375,20.150075 Z"
            id="Fill-15"
            fill="#FBB03B"
          ></path>
          <path
            d="M46.2107,17.3273 C46.2317,17.3468 46.25495,17.36255 46.27595,17.3828 C48.4742,19.4303 49.59095,21.9953 49.59095,25.00205 C49.59095,27.1628 49.14095,29.37455 48.24845,31.57955 L47.34095,33.5453 C44.74595,38.51705 40.37345,42.6053 37.12595,45.1778 C33.6992,47.89355 30.8222,49.51505 30.04595,49.8878 L37.25945,58.45205 C49.9607,55.1888 59.3747,43.64255 59.3747,29.93705 C59.3747,28.9403 59.3237,27.9548 59.2262,26.9828 L46.27595,17.1968 L46.2107,17.3273 Z"
            id="Fill-17"
            fill="#06776C"
          ></path>
          <path
            d="M29.937425,0.499925 C13.705175,0.499925 0.499925,13.705175 0.499925,29.937425 C0.499925,46.169675 13.705175,59.374925 29.937425,59.374925 C46.169675,59.374925 59.374925,46.169675 59.374925,29.937425 C59.374925,13.705175 46.169675,0.499925 29.937425,0.499925"
            id="Path"
            stroke="#FFFFFF"
            strokeWidth="2"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default IconNavHeartActive;
