import React from "react";
import {Row, Col, Card} from "react-bootstrap";
import {setPageInformationAction} from "../redux/actions";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import routes, { ROUTE_TITLES } from "../routes/routes";
import { botflows_scenario } from "../botflows";
import OverviewService from "../service/OverviewService";
import { localizations } from "../lang/localization";
import {withOktaAuth} from "@okta/okta-react";

class Caisr extends React.Component {
  service = new OverviewService();
  intl = this.props.intl;

  constructor(props) {
    super(props);
    this.onCaisrFlow();

    this.state = {
      ready: false,
      language: this.props.locale,
    }
  }

  onCaisrFlow = async () => {
    const history = this.props.history;
    const currentCarePlans = await this.service.getPatientCarePlans();
    const anyCaisrCareplan = currentCarePlans.includes('caisr') || currentCarePlans.includes('Caisr') || currentCarePlans.includes('CAISR');
    if(!anyCaisrCareplan) {
      // if the patient needs to be registred to caisr
      localStorage.setItem("botTriggerAction", `${botflows_scenario.caisr_onboarding}`);
      sessionStorage.setItem("addCarePlan", 'caisr');

      const pageInfo = {
        pageHeader: ROUTE_TITLES[routes.caisr],
        previousPath: "",
        botTriggerAction: {
          scenario: botflows_scenario.caisr_onboarding,
        },
        observationCode: ""
      };

      this.props.setPageInformation(pageInfo);
      history.push(routes.addcaisr);
    } else {
      this.setState({ ready: true })
    }
  };

  onCaisrReportClick = () => {  
    const history = this.props.history;
    const pageInfo = {
          pageHeader: ROUTE_TITLES[routes.caisr],
          previousPath: "",
          observationCode: "",
          botTriggerAction: {
            scenario: botflows_scenario["report_caisr"]
        }
    };

    this.props.setPageInformation(pageInfo);

    localStorage.setItem("botTriggerAction", "report_caisr");
    localStorage.setItem("pageHeader", ROUTE_TITLES[routes.caisr]);

    history.push({
      pathname:  routes.reportcaisr
    });
  }

  on1177Click = () => {  
    const history = this.props.history;
    const pageInfo = {
          pageHeader: ROUTE_TITLES[routes.chat1177],
          previousPath: "",
          observationCode: "",
          botTriggerAction: {
            scenario: botflows_scenario["caisr_1177"]
          },
          hideSendBox: false
    };

    this.props.setPageInformation(pageInfo);

    localStorage.setItem("botTriggerAction", "caisr_1177");
    localStorage.setItem("pageHeader", ROUTE_TITLES[routes.chat1177]);

    history.push({
      pathname:  routes.chat1177
    });
  }

  onDetailsClick = () => {
    const history = this.props.history;
    
    const pageHeader = this.intl.formatMessage({ id: "4869002" });
    
    const pageInfo = {
      pageHeader,
      previousPath: routes.caisr,
      observationCode: "4869002"
    };

    this.props.setPageInformation(pageInfo);

    localStorage.setItem("pageHeader", pageHeader);

    history.push({
      pathname: routes.details,
      state: { id: "4869002" }
    });
  }

  handleLangSelectChange = (e) => {
      localStorage.setItem("lang", e.target.value);
      window.location.reload();
  }
  
  logout = async () => {
      const appDomain = window.location.origin;
      this.props.oktaAuth.tokenManager.clear();
      sessionStorage.removeItem('gotomeeting')
      await this.props.oktaAuth.signOut();
      window.location.replace(appDomain);
  }

  render = () => {
    return (        
      this.state.ready ? <>
          <Row>
            <Col md={6} className="d-flex px-0 px-md-4">
              <Card className="bp-card p-4 text-center mb-4" onClick={() => {
                this.onCaisrReportClick();
              }}>
                <h4 className="font-weight-bold mt-2">{this.intl.formatMessage({ id: "hr_anxiety_day_how_was_it" })}</h4>
              </Card>
            </Col>
            
            <Col md={6} className="d-flex px-0 px-md-4">
              <Card className="bp-card p-4 text-center mb-4" onClick={() => {
                this.onDetailsClick();
              }}>
                <h4 className="font-weight-bold mt-2">{this.intl.formatMessage({ id: "page_details" })}</h4>
              </Card>
            </Col>

            <Col md={6} className="d-flex px-0 px-md-4">
              <Card className="bp-card p-4 text-center mb-4" onClick={() => {
                this.on1177Click();
              }}>
                <h4 className="font-weight-bold mt-2">{this.intl.formatMessage({ id: "ask_1177" })}</h4>
              </Card>
            </Col>
            
            <Col md={6} className="d-flex px-0 px-md-4">
              <Card className="bp-card mb-4">
                  <Card.Body>
                      <Row>
                          <Col xs={12} className="d-flex justify-content-center">
                              <h5 className="font-weight-bold text-uppercase mb-3">{this.intl.formatMessage({ id: "settings_language" })}</h5>
                          </Col>
                      </Row>
                      <Row>
                          <Col xs={12} className="d-flex justify-content-center">
                              <select value={this.state.language} onChange={this.handleLangSelectChange} className="lan-dropdown form-control">
                                  {localizations.map((localization)=> {
                                      return (
                                          <option
                                              value={localization.languageCode}
                                              key={localization.languageCode}
                                          >{localization.name}</option>
                                      )
                                  })}
                              </select>
                          </Col>
                      </Row>
                  </Card.Body>
              </Card>
            </Col>

            <Col md={6} className="d-flex px-0 px-md-4">
                    <Card className="bp-card mb-2 mb-md-5">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <h5 className="font-weight-bold text-uppercase mb-0">{this.intl.formatMessage({ id: "settings_logout" })}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <button
                                      className="register-button mt-3"
                                      onClick={this.logout}
                                    >{this.intl.formatMessage({ id: "settings_logout_click_exit" })}</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
          </Row>

        </> : <div className="loading-spinner"></div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}

export default connect(null, mapDispatchToProps)(withOktaAuth(injectIntl(Caisr)));
