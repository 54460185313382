const formatValue = (values) => {
  if (values.length > 1) {
    if(values[0].value && values[1].value){
      return `${values[0].value}/${values[1].value}`;
    }
    return `${values[0].code}_${values[1].code}`;
  } else if (values[0].value) {
    return values[0].value;
  } else if (typeof(values[0].objectValue) == 'boolean') {
    return values[0].objectValue ? "mystats_unit_yes" : "mystats_unit_no";
  } else if (values[0].value === 0) {
    return values[0].value;
  } else {
    return values[0].code
  }
}

const createCards = (data, arr) => {
  return data.map(item => {
    const code = Object.keys(item)[0];

    if (typeof item[code] === "string") {
      let value = arr.filter(entity => entity.code === code);
      // round BMI
      if(code === "39156-5"){
        value[0].value = Math.round(value[0].value * 10) / 10
      }
      return { ...value[0], label: item[code] }
    } else {
      return {
        [code]: createCards(item[code], arr)
      }
    }
  });
}

const removeUnusedValues = (arr) => {
  const bloodPressureValue = arr.filter(item => item.code === "366157005")[0]?.value;
  if (bloodPressureValue === "103330002") {
    return arr.filter(item => item.code !== "422979000")
  }
  return arr;
}

export const formatStatistics = (data, schema) => {
  const codes = Object.keys(data);
  const arr = codes.map(code => {
    if (data[code]?.id) {
      return {
        code: code,
        value: data[code].value
          ? formatValue(data[code].value)
          : "mystats_unit_no",
        unit: data[code].value ? data[code].value[0].unit : null,
        date: data[code].effectiveDateTime
      }
    } else {
      return {
        code: code,
        value: "goals_click_to_report",
        notReported: true
      }
    }
  });

  return createCards(schema, removeUnusedValues(arr));
}

export const formatStatisticsArr = (schema, data) => {

  const arr = data.map(item => {
    if (item[1]?.id) {
      return {
        code: item[0],
        value: item[1].value
          ? formatValue(item[1].value)
          : "mystats_unit_no",
        unit: item[1].value ? item[1].value[0].unit : null,
        date: item[1].effectiveDateTime
      }
    } else {
      return {
        code: item[0],
        value: "goals_click_to_report",
        notReported: true
      }
    }
  });

  return createCards(schema, removeUnusedValues(arr));
}

export const createSchema = (arr) => {
  let statScheme = [
    {
      "mystats_about_you": [],
    },
    {
      "mystats_habits": [],
    },
    {
      "mystats_sleep": [],
    },
    {
      "mystats_blood_pressure_status": [],
    }
  ];

  // build schema
  arr.forEach(code => {
    var obj = {};
    obj[code[0]] = code[0];

    switch (code[0]) {
      case "410668003":
      case "8302-2":
        statScheme[0].mystats_about_you.push({ [code[0]]: "mystats_length" })
        break;
      case "39156-5":
        statScheme[0].mystats_about_you.push({ [code[0]]: "39156-5" })
        break;
      case "711013002":
        statScheme[1].mystats_habits.push({ "711013002": "mystats_smokes" })
          break;
      case "713127001":
        statScheme[1].mystats_habits.push({ "713127001": "mystats_alcohol" })
        break;
      case "72863001":
        statScheme[2].mystats_sleep.push({ "72863001": "mystats_snoring" })
        break;
      case "141000119100":
        statScheme[2].mystats_sleep.push({ "141000119100": "mystats_often_tired" })
        break;
      case "103750000":
        statScheme[2].mystats_sleep.push({ "103750000": "mystats_not_breathing" })
        break;
      case "366157005":
        statScheme[3].mystats_blood_pressure_status.push({ "366157005": "mystats_confirmed_as_high" })
        break;
      case "422979000":
        statScheme[3].mystats_blood_pressure_status.push({ "422979000": "mystats_on_medication" })
        break;
      case "93341-6":
        statScheme[3].mystats_blood_pressure_status.push({ "93341-6": "93341-6" })
        break;
      default:
        statScheme.push(obj)
        break;
    }
    
  })
  
  for (let index = 3; index > 0; index--) {
    let data = statScheme[index][Object.keys(statScheme[index])[0]]
    if(data.length < 1){
      statScheme.splice(index,1);
    }
  }

  // let base value be at bottom of scheme
  let baseObs = [];
  let allObs = [];
  statScheme.forEach((element, index) => {
    let keyVal = Object.keys(Object.values(statScheme)[index])[0];
    if(keyVal.includes("mystats")){
      baseObs.push(element)
    } else {
      allObs.push(element)
    }
  });

  allObs.push(...baseObs)

  return allObs;
}