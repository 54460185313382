import React, { useEffect, useState } from 'react'
import {Row, Col} from "react-bootstrap";
import moment from 'moment-with-locales-es6';

const ChartNavigation = ({ period = 'week', onNavigate, locale, intl  }) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const minDate = moment('2020-01-01')
  const maxDate = moment()

  const periods = {
    day: {
      isMinDate: () => {
        return endDate.format('YYYY') === minDate.format('YYYY') && endDate.format('D') === minDate.format('D')
      },
      isMaxDate: () => {
        return endDate.format('YYYY') === maxDate.format('YYYY') && endDate.format('D') === maxDate.format('D')
      },
      getInitialDateRange: () => {
        const endDate = moment()
        const startDate = moment()

        return { startDate, endDate }
      },
      getLabel: () => {
        return endDate.format('YYYY-MM-DD')
      }
    },
    week: {
      isMinDate: () => {
        return endDate.format('YYYY') === minDate.format('YYYY') && endDate.isoWeek() === minDate.isoWeek()
      },
      isMaxDate: () => {
        return endDate.format('YYYY') === maxDate.format('YYYY') && endDate.isoWeek() === maxDate.isoWeek()
      },
      getInitialDateRange: () => {
        const startDate = moment().locale(locale).startOf('week');
        const endDate = moment().locale(locale).endOf('week');

        return { startDate, endDate }
      },
      getLabel: () => {
        return `${intl.formatMessage({
          id: 'chart_week'
        })} ${endDate.isoWeek()} - ${endDate.format('YYYY')}`
      }
    },
    month: {
      isMinDate: () => {
        return endDate.format('YYYY') === minDate.format('YYYY') && endDate.format('M') === minDate.format('M')
      },
      isMaxDate: () => {
        return endDate.format('YYYY') === maxDate.format('YYYY') && endDate.format('M') === maxDate.format('M')
      },
      getInitialDateRange: () => {
        const startDate = moment().startOf('month');
        const endDate = moment().endOf('month');

        return { startDate, endDate }
      },
      getLabel: () => {
        return endDate.locale(locale).format('MMMM YYYY')
      }
    },
    year: {
      isMinDate: () => {
        return endDate.format('YYYY') === minDate.format('YYYY')
      },
      isMaxDate: () => {
        return endDate.format('YYYY') === maxDate.format('YYYY')
      },
      getInitialDateRange: () => {
        const startDate = moment().startOf('year');
        const endDate = moment().endOf('year');

        return { startDate, endDate }
      },
      getLabel: () => {
        return endDate.format('YYYY')
      }
    }
  }

  useEffect(() => {
    if (!Object.keys(periods).includes(period)) {
      throw new Error(`Unsupported chart period ${period}`)
    }

    const { startDate, endDate } = periods[period].getInitialDateRange()

    setStartDate(startDate)
    setEndDate(endDate)
  }, [period])

  const onPrevious = () => {
    setStartDate(startDate.clone().subtract(1, period))
    setEndDate(endDate.clone().subtract(1, period))
    
    handleDetailsToggle();
  }

  const onNext = () => {
    setStartDate(startDate.clone().add(1, period))
    setEndDate(endDate.clone().add(1, period))

    handleDetailsToggle();
  }

  const handleDetailsToggle = () => {
    if(document.getElementById("observationsDetails") && !document.getElementById("observationsDetails")?.classList?.value.includes("collapse")){
      console.log(document.getElementById("observationsDetails").classList)
      document.getElementById("observationsDetails").classList.add("collapse");
      document.getElementById("observationsDetailsArrow").classList.remove("icon-rotate");
    }
  }

  useEffect(() => {
    if (!startDate || !endDate) {
      return
    }

    onNavigate(startDate, endDate)
  }, [startDate, endDate])

  if (!startDate || !endDate) {
    return null
  }

  return (
    <Row>
      <Col className="d-flex justify-content-center align-items-center">
        <button
          onClick={onPrevious}
          disabled={periods[period].isMinDate()}
          className="chart-period-arrows text-right"
        >{'<'}</button>

        <span
          className="chart-period-label"
        >
          {periods[period].getLabel()}
        </span>

        <button
          onClick={onNext}
          disabled={periods[period].isMaxDate()}
          className="chart-period-arrows text-left"
        >{'>'}</button>
      </Col>
    </Row>
  )
}

export default ChartNavigation
