import React, { useState, useEffect } from "react";

const Modal = ({ modal, onClose }) => {
    const [hidden, setHidden] = useState(true)

    modal = {
        content: null,
        ...modal
    }

    const onCloseModal = () => {
        setHidden(true);

        setTimeout(onClose, 200);
    }

    const { content } = modal

    useEffect(() => {
        if (content) {
            setTimeout(() => {
                setHidden(false);
            }, 500);
        } else {
            setHidden(true);
        }
    }, [content]);

    return content ? (
        <>
            <div className={`modal-overlay ${hidden ? 'is-hidden' : ''}`} onClick={onCloseModal} />
            <div className={`modal-window ${hidden ? 'is-hidden' : ''}`}>
                {content}
            </div>
        </>
    ) : null
}

export default Modal
