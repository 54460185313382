import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./index.css";
import { Provider } from "react-redux";
import reduxStore from "./redux/reduxStore";
import intlConfig from "./lang/intlConfig";

ReactDOM.render( 
  <Provider store={reduxStore}>
    <IntlProvider {...intlConfig}>
      <Router>
        <App locale={intlConfig.locale}/>
      </Router>
    </IntlProvider>
  </Provider>
,
document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
