import React from "react";

const IconNavReportActive = () => (
  <svg
    width="62px"
    height="62px"
    viewBox="0 0 62 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="NavBar" transform="translate(-53.000000, -15.000000)">
        <g id="navicon_add" transform="translate(54.000000, 16.000000)">
          <path
            d="M29.9375,0.499925 C13.70525,0.499925 0.5,13.705175 0.5,29.937425 C0.5,46.169675 13.70525,59.374925 29.9375,59.374925 C46.16975,59.374925 59.375,46.169675 59.375,29.937425 C59.375,13.705175 46.16975,0.499925 29.9375,0.499925"
            id="Path"
            fill="#FFFFFF"
          ></path>
          <path
            d="M51.0869,20.5679 C52.0994,21.6629 52.9244,23.0129 53.7119,24.4004 L53.7119,26.1854 C52.4594,26.7779 51.7019,27.9254 50.7644,28.8629 C44.7794,34.8704 38.7869,40.8779 32.8844,46.9679 C31.5719,48.3179 30.1544,49.1204 28.3169,49.4279 C26.0069,49.8179 23.7194,50.3879 21.1244,50.9429 L29.5844,59.3729 C13.5119,59.1779 0.4994,46.0529 0.4994,29.9354 C0.4994,13.7054 13.7069,0.4979 29.9369,0.4979 C44.1044,0.4979 55.9619,10.5554 58.7519,23.9054 L51.0869,20.5679 Z"
            id="Fill-4"
            fill="#3185DE"
          ></path>
          <path
            d="M53.7119,24.400325 L53.7119,26.185325 C52.4594,26.777825 51.7019,27.925325 50.7644,28.862825 C44.7794,34.870325 38.7869,40.877825 32.8844,46.967825 C31.5719,48.317825 30.1544,49.120325 28.3169,49.427825 C26.0069,49.817825 23.7194,50.387825 21.1244,50.942825 C21.7919,47.552825 22.3619,44.515325 23.0069,41.492825 C23.1044,41.020325 23.4644,40.555325 23.8169,40.202825 C31.0319,32.897825 38.2694,25.607825 45.4994,18.317825 C45.5969,18.220325 45.7544,18.175325 46.1444,17.957825 C48.2669,18.197825 49.8194,19.195325 51.0869,20.567825 C52.0994,21.662825 52.9244,23.012825 53.7119,24.400325"
            id="Fill-6"
            fill="#B3B3B3"
          ></path>
          <path
            d="M25.3778,40.442225 C24.0833,41.789975 23.9423,43.373225 23.78405,45.059975 C25.23455,45.794225 26.11655,47.105975 27.3143,48.273725 C28.6928,47.685725 30.45155,48.071975 31.3823,46.349975 C30.5528,43.204475 28.54805,41.315975 25.3778,40.442225"
            id="Fill-8"
            fill="#F2F2F2"
          ></path>
          <path
            d="M24.88925,13.485425 L24.88925,31.501175 C24.2855,31.209425 22.466,30.217175 20.3435,28.600925 C18.2315,26.995175 14.89325,23.277425 13.3265,20.191925 C12.8885,19.023425 12.6545,17.845925 12.6545,16.697675 C12.6545,14.896925 13.36475,13.369175 14.74775,12.151925 C16.07075,10.984175 17.71625,10.390925 19.6235,10.390925 C22.16375,10.390925 23.8865,11.402675 24.88925,13.485425"
            id="Fill-10"
            fill="#FBB03B"
          ></path>
          <path
            d="M37.4159,16.69745 C37.4159,17.9822 37.1339,19.30595 36.5789,20.61995 C34.9439,23.58845 31.61465,27.18995 29.5709,28.7282 C27.29315,30.43145 25.39565,31.41395 25.0349,31.56995 C24.9959,31.55045 24.94715,31.53095 24.8894,31.5017 L24.8894,13.4852 L25.0349,13.7972 L25.1909,13.4852 C26.1839,11.40245 27.90665,10.3907 30.45665,10.3907 C32.36465,10.3907 33.99965,10.98395 35.33315,12.1517 C36.71465,13.36895 37.4159,14.8967 37.4159,16.69745"
            id="Fill-12"
            fill="#F7931E"
          ></path>
          <path
            d="M59.37425,29.93525 C59.37425,46.17275 46.16675,59.37275 29.93675,59.37275 L29.58425,59.37275 L21.12425,50.94275 C23.71925,50.38775 26.00675,49.81775 28.31675,49.42775 C30.15425,49.12025 31.57175,48.31775 32.88425,46.96775 C38.78675,40.87775 44.77925,34.87025 50.76425,28.86275 C51.70175,27.92525 52.45925,26.77775 53.71175,26.18525 L53.71175,24.40025 C52.92425,23.01275 52.09925,21.66275 51.08675,20.56775 L58.75175,23.90525 C59.15675,25.85525 59.37425,27.87275 59.37425,29.93525"
            id="Fill-14"
            fill="#2563A6"
          ></path>
          <path
            d="M29.9375,0.499925 C13.70525,0.499925 0.5,13.705175 0.5,29.937425 C0.5,46.169675 13.70525,59.374925 29.9375,59.374925 C46.16975,59.374925 59.375,46.169675 59.375,29.937425 C59.375,13.705175 46.16975,0.499925 29.9375,0.499925"
            id="Path"
            stroke="#FFFFFF"
            strokeWidth="2"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default IconNavReportActive;
