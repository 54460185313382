import React, {Fragment} from "react";
import {Row, Col} from "react-bootstrap";
import {connect} from "react-redux";
import {setPageInformationAction} from "../redux/actions";
import {withRouter} from 'react-router-dom';
import {injectIntl} from "react-intl";
import routes, {ROUTE_TITLES} from "../routes/routes";
import { botflows_scenario } from "../botflows";

class GraphDetails extends React.Component {
  onReportClick = () => {
    const history = this.props.history;
    const pageInfo = {
      pageHeader: ROUTE_TITLES[routes.report],
      previousPath: "",
      botTriggerAction: this.props.pageInfo.botTriggerAction,
      observationCode: this.props.observationCode ?? ""
    };

    this.props.setPageInformation(pageInfo);
    history.push(routes.report);
  }
  
  onCaisrReportClick = () => {  
    const history = this.props.history;
    const pageInfo = {
          pageHeader: ROUTE_TITLES[routes.caisr],
          previousPath: "",
          observationCode: "",
          botTriggerAction: {
            scenario: botflows_scenario["report_caisr"]
        }
    };

    this.props.setPageInformation(pageInfo);

    localStorage.setItem("botTriggerAction", "report_caisr");
    localStorage.setItem("pageHeader", ROUTE_TITLES[routes.caisr]);

    history.push({
      pathname:  routes.reportcaisr
    });    
  }

  render = () => {
    const {intl} = this.props;

    return (
        <Row>
          <Col className="d-flex flex-column" md={{span: 8, offset: 2}}>
            {this.props.details !== undefined && this.props.details.length > 0 && this.props.observationCode !== "443450003" &&
            this.props.details.map((item, index) => (
              <Fragment key={index}>
                <Row>
                  <Col xs={7} className="pr-0">
                    <h5>{this.props.observationCode === "4869002" ? intl.formatMessage({id: "hr_anxiety_level"})+item.label : item.label}</h5>
                  </Col>
                  <Col xs={5}>
                    <h5 className="text-right">{item.value} <small>{item.unit}</small></h5>
                  </Col>
                </Row>
              </Fragment>
            ))}
            <Row className="order-md-0">
              <Col className="text-center mb-3">
                {this.props.observationCode === "4869002" ? 
                  <button variant="outline-info" className="details-button mt-3" onClick={this.onCaisrReportClick}>
                    {intl.formatMessage({id: "chart_report"})}
                  </button> :                   
                  <button variant="outline-info" className="details-button mt-3" onClick={this.onReportClick}>
                    {intl.formatMessage({id: "chart_report"})}
                  </button>
                }
              </Col>
            </Row>
          </Col>
        </Row>
    )
  }
}

const mapStateToProps = (state) => {
  if (state.pageInfo.botTriggerAction === "") {
    const action = localStorage.getItem("botTriggerAction")?.split("#");
    const info = {
      pageHeader: localStorage.getItem("pageHeader"),
      botTriggerAction: {
        scenario: action ? action[0] : "",
        card: action ? action[1] : "",
      },
      previousPath: localStorage.getItem("previousPath")
    }
    return {pageInfo: info};
  }
  return {pageInfo: state.pageInfo};
}
const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(GraphDetails)));
