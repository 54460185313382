import React from "react";
import {Card, Row, Col} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {setPageInformationAction} from "../redux/actions";
import ArticleService from "../service/ArticleService";
import routes, {ROUTE_TITLES} from "../routes/routes";
import ReactStars from "react-stars/dist/react-stars";
import {injectIntl} from "react-intl";

const createDOMPurify = require('dompurify');
const {JSDOM} = require('jsdom');

class Article extends React.Component {
  window = new JSDOM('').window;
  DOMPurify = createDOMPurify(window);
  articleId = this.props.match.params.id;
  service = new ArticleService();

  constructor(props) {
    super(props);
    this.state = {
      article: {},
      rating: 0,
    };
  }

  componentDidMount() {
    this.getArticle();
    this.props.setPageInformation({ pageHeader: ROUTE_TITLES[routes.article] })
  }

  getArticle = () => {
    this.service.getArticle(this.articleId).then((response) => {
      this.setState({
        article: response,
        rating: response.readership?.rating,
      });
    });
  }

  ratingChanged = (rating) => {
    this.setState({ rating })
  };

  submitRating = () => {
    const params = {
      articleId: this.articleId,
      rating: this.state.rating
    };

    this.service.rateArticle(params).then(() => {
      this.props.history.push(routes.facts)
    })
  };

  render = () => {
    const { fullArticle } = this.state.article;
    const { intl } = this.props;

    if (!fullArticle) return null;

    return (
      <Row>
        <Col className="px-0 px-md-4">
          <Card className="border-0">
            <Card.Body className="text-center">
              <h3 className="article-title">{fullArticle.title}</h3>
              <p className="text-left mb-2 font-weight-bold">{fullArticle.subtitle}</p>
              <img alt="" aria-hidden="false" src={fullArticle.thumbnailUri}/>
              <div className="text-left article-text"
                   dangerouslySetInnerHTML={{__html: this.DOMPurify.sanitize(fullArticle.htmlContent)}}/>
              <h5 className="text-center mt-2">
                {intl.formatMessage({ id: fullArticle.category === "medical" ? "article_rating_medical" : "article_rating_lifestyle" })}
              </h5>
              <div className="rating d-flex justify-content-center">
                <ReactStars
                  count={5}
                  onChange={this.ratingChanged}
                  value={this.state.rating}
                  size={35}
                  color2={'#ffd700'}
                  half={false}
                />
              </div>
              <button variant="outline-info" className="register-button ml-n2 mt-0" onClick={this.submitRating}>
                {intl.formatMessage({ id: "article_rating_submit" })}
              </button>
              {fullArticle.category === "lifestyle" && (
                <p className="mt-4"><small>{intl.formatMessage({ id: "article_disclaimer_lifestyle" })}</small></p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {pageInfo: state.pageInfo};
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Article)));
