import { combineReducers } from "redux";
import routes from "../routes/routes";
import * as actionTypes from "./action-types";

const userIntialState = {
    userName: "",
    token: "",
    patientId: "",
    goals: {}
};

const viewIntialState = {
    pageHeader: "overview",
    previousPath:routes.overview,
    botTriggerAction: "",
    observationCode: ""
};

const goalsInitialState = {
    weight: null,
    steps: null,
    activity: null,
}

const userInfo = (state = userIntialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_INFORMATION:
            return {
                ...state,
                userName: action.payload.userName,
                patientId: action.payload.patientId,
                token: action.payload.token
            };

        default:
            return state;
    }
};

const pageInfo = (state = viewIntialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGE_INFORMATION:
            return {
                ...state,
                pageHeader: action.payload.pageHeader,
                previousPath:action.payload.previousPath,
                observationCode: action.payload.observationCode,
                botTriggerAction: action.payload.botTriggerAction,
                hideSendBox: action.payload.hideSendBox === null || action.payload.hideSendBox === undefined ? true : action.payload.hideSendBox
            };

        default:
            return state;
    }
};

const userGoals = (state = goalsInitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_GOALS:
            return { ...action.payload };
        default:
            return state;
    }
}

export default combineReducers({ userInfo, pageInfo, userGoals });
