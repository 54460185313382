import axios from "axios";
import { reject } from "q";
import { environment } from "../environment";
import reduxStore from "../redux/reduxStore";

export default class ApiService {
    apiCall = async (apiOptions)  => {
        const headers = this.getCustomHeaders();

        const options = {
            method: apiOptions.method,
            url: `${environment.webApiUrl}/${apiOptions.apiMethod}`,
            params: apiOptions.params,
            headers: headers,
            data: apiOptions.data,
            responseType: apiOptions.responseType || 'json'
        };

        try {
            const response = await axios(options);

            return response;
        } catch (error) {
            console.warn(error);

            return reject(error);
        }
    }

    getCustomHeaders = () => { 
        const state = reduxStore.getState();
        
        return {
            "Access-Control-Allow-Origin":"*",
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${state.userInfo.token}`
        };
    }
}
