import React, { useState } from "react";
import { useIntl } from "react-intl";
import moment from 'moment-with-locales-es6';

const CreateReportModal = ({ carePlans, myService, setModal }) => {
    const intl = useIntl();
    const [period, setPeriod] = useState('ten_days');
    // don't set main pain careplan or meeting careplan as default values in dropdown
    const [carePlan, setCarePlan] = useState(carePlans.filter(item => item !== "Pain" && item !== "meeting")[0]?.toLowerCase());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    if (!carePlans) {
        carePlans = [
            'hypertension',
            'diabetes',
            'asthma',
            'copd',
            'pain'
        ];
    } else {
        carePlans = carePlans.map((plan) => plan.toLowerCase());
    }

    // filter to only allow the careplans that are supposed to be shown
    carePlans = carePlans.filter(plan => plan === 'hypertension' || plan.includes('pain.') || plan === "asthma");

    const periods = [
        // 'day', 
        // 'week', 
        'ten_days', 
        'month', 
        'three_months', 
        'year'];

    const aggregations = {
        day: 'hour',
        week: 'weekday',
        ten_days: 'day',
        month: 'day',
        three_months: 'day',
        year: 'month'
    };
    

    const onCreateReport = () => {
        let startDate = moment();
        let endDate = moment();

        if (period === 'day') {
            startDate = startDate.subtract(1, period);
        } else if (period === 'week') {
            startDate = startDate.subtract(6, 'days');
        } else if (period === 'ten_days') {
            startDate = startDate.subtract(9, 'days');
        } else if (period === 'month') {
            startDate = startDate.subtract(1, 'month').add(1, 'day');
        } else if (period === 'three_months') {
            startDate = startDate.subtract(3, 'month').add(1, 'day');
        } else if (period === 'year') {
            startDate = startDate.subtract(1, 'year').add(1, 'month');
        }

        setLoading(true);

        var fileWin = window.open('', '_blank');
        fileWin.document.write(intl.formatMessage({ id: 'settings_withings_loading_text'}));

        myService.createReport({
            start: startDate.format('YYYY-MM-DD'),
            end: endDate.format('YYYY-MM-DD'),
            aggregation: aggregations[period],
            carePlanName: carePlan,
            culture: intl.locale ?? 'en',
            created: moment().format('YYYY-MM-DDTHH:mm:ss')
        },carePlan).then((data) => {
            const file = new Blob([data], {
                type: 'application/pdf'
            });

            const fileURL = URL.createObjectURL(file);
            fileWin.location.href = fileURL;

            setModal({});
        }).catch(error => {
            fileWin.close();
            setError(true)
            setLoading(false)
        });

    }

    return (
        <div className="card-body" style={loading ? { cursor: 'wait' } : {}}>
            <div className="row">
                <div className="d-flex justify-content-center col-12">
                    <h5 className="font-weight-bold text-uppercase mb-3">
                        {intl.formatMessage({ id: "settings_report_create" })}
                    </h5>
                </div>
            </div>

            {error ?
            <>
                <div className="row">
                    <div className="d-flex justify-content-center col-12">
                        <p>{intl.formatMessage({ id: "settings_report_create_error" })}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center col-12">
                    <button
                                className="register-button mt-3"
                                onClick={() => setModal({})}
                            >
                                {intl.formatMessage({ id: 'close'})}
                            </button>
                    </div>
                </div>
            </> :
            <>
                <div className="row align-items-center mt-2">
                    <div className="d-flex col-6">
                        {intl.formatMessage({ id: "settings_school" })}
                    </div>

                    <div className="d-flex justify-content-center col-6 pl-0">
                        <select
                            className="care-plan-dropdown form-control"
                            onChange={(e) => setCarePlan(e.target.value)}
                            value={carePlan}
                            disabled={loading}
                        >
                            {carePlans.length ? carePlans.map((key) => {
                                let optionName = ''

                                if(key.includes('pain.')){
                                    optionName = intl.formatMessage({ id: `settings_school_pain` }) + " - " + intl.formatMessage({ id: `${key.replace('pain.','')}` })
                                } else {
                                    optionName = intl.formatMessage({ id: `settings_school_${key}` })
                                }
                                return (
                                    <option value={key} key={key}>
                                        {optionName}
                                    </option>
                                )
                            }) : (
                                <option value="" disabled selected>
                                    {intl.formatMessage({ id: `settings_school_empty` })}
                                </option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="row align-items-center mt-2">
                    <div className="d-flex col-6">
                        {intl.formatMessage({ id: "settings_report_period" })}
                    </div>

                    <div className="d-flex justify-content-center col-6 pl-0">
                        <select
                            className="period-dropdown form-control"
                            onChange={(e) => setPeriod(e.target.value)}
                            value={period}
                            disabled={loading}
                        >
                            {periods.map((key) => {
                                return (
                                    <option value={key} key={key}>
                                        {intl.formatMessage({ id: `settings_report_period_${key}` })}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="d-flex justify-content-center col-12">
                        <button
                            className={`register-button mt-3 ${loading ? 'is-loading' : ''}`}
                            onClick={onCreateReport}
                            disabled={loading || !carePlans.length}
                        >
                            {intl.formatMessage({ id: loading ? 'settings_report_create_button_loading' : 'settings_report_create_button' })}
                        </button>
                    </div>
                </div>
            </>
            }
        </div>
    );
}

export default CreateReportModal;