export const environment = {
   webApiUrl: "https://app-cscp-backend-dev-we.azurewebsites.net/api/v1",
   issuer: 'https://selfcare-login-qua.ramsaysante.com',
   clientId: '0oa18rqcix7SolGDD0x7',
   botApiUrl: "https://func-cscp-tokengenerator-we-dev.azurewebsites.net/api",
   dateFormat: "YYYY-MM-DD",
   overviewObservations : "85354-9",
   mystatsObservations : "85354-9,29463-7,41950-7,68130003,22253000,711013002,713127001,410668003,72863001,141000119100,103750000,366157005,422979000",
   goals: "29463-7,68130003,41950-7,8480-6,8462-4",
   withingsClientId: "8091c4e49467c1cabb38221718c64d30136c3e9c5ad9d78eec730de682518fac",
   withingsRedirectUri: "https://selfcareportal-dev.azureedge.net/withings",
   capioIdp: "",
   capioPathname: "/capio",
   feedbackFormUrl: "https://forms.office.com/r/6YWh53dEAH",
};
