import { createIntl } from "react-intl";
import { environment } from "../environment";
import intlConfig from "../lang/intlConfig";

const intl = createIntl(intlConfig);

const routes = {
    overview: "/overview",
    login: "/",
    capio: environment.capioPathname,
    settings: "/settings",
    tracks: "/tracks",
    mystats: "/mystats",
    article: "/article",
    facts: "/facts",
    caisr: "/caisr",
    reportcaisr: "/reportcaisr",
    chat1177: "/chat1177",
    meeting: "/meeting",
    meetings: "/meetings",
    details: "/details",
    report: "/report",
    addmeeting: "/addmeeting",
    addcaisr: "/addcaisr",
    withings: "/withings",
    conditions: "/conditions",
    statistics: "/statistics",
    observations: "/observations",
};

export const ROUTE_TITLES = {
    [routes.overview]: intl.formatMessage({ id: "page_overview" }),
    [routes.mystats]: intl.formatMessage({ id: "page_mystats" }),
    [routes.facts]: intl.formatMessage({ id: "page_facts" }),
    [routes.caisr]: intl.formatMessage({ id: "caisr" }),
    [routes.addcaisr]: intl.formatMessage({ id: "caisr" }),
    [routes.reportcaisr]: intl.formatMessage({ id: "caisr" }),
    [routes.chat1177]: "1177",
    [routes.meeting]: intl.formatMessage({ id: "meeting_meeting" }),
    [routes.meetings]: intl.formatMessage({ id: "meeting_meetings" }),
    [routes.meetings]: intl.formatMessage({ id: "meeting_meetings" }),
    [routes.addmeeting]: intl.formatMessage({ id: "meeting_meetings" }),
    [routes.settings]: intl.formatMessage({ id: "page_settings" }),
    [routes.tracks]: intl.formatMessage({ id: "page_tracks" }),
    [routes.article]: intl.formatMessage({ id: "page_article" }),
    [routes.report]: intl.formatMessage({ id: "page_report" }),
    [routes.withings]: intl.formatMessage({ id: "page_withings" }),
    [routes.statistics]: intl.formatMessage({ id: "page_statistics" }),
    [routes.observations]: intl.formatMessage({ id: "page_observations" }),
}

export default routes;
