import React from "react";
import {Row, Col, Card} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {setPageInformationAction} from "../redux/actions";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import MeetingService from "../service/MeetingService";
import moment from 'moment-with-locales-es6';

class Meetings extends React.Component {
  service = new MeetingService();
  intl = this.props.intl;

  constructor(props) {
    super(props);
    this.state = {
      meetings: null,
      loading: true
    }
  }

  goToMeeting = (link, id, subject, content) => {
    const { history } = this.props; 
    const meetingObj = {link, id, subject, content}

    sessionStorage.setItem("meetinginfo", JSON.stringify(meetingObj));

    history.push(`/meeting/${id}`);
  }
  
    
  async componentDidMount() {
      if(sessionStorage.getItem("gotomeeting")){
        const patientCarePlans = await this.service.getPatientCarePlans();
        await this.service.addPatientToMeeting(sessionStorage.getItem("gotomeeting")).then(() => {  
          if(patientCarePlans.includes('meeting')){
            sessionStorage.removeItem("gotomeeting")
          }
        }).catch(e => {          
          if(patientCarePlans.includes('meeting')){
            sessionStorage.removeItem("gotomeeting")
          }
          console.log(e)
        });
      }
      
      const allPatientMeetings = await this.service.getPatientMeetings();
      
      this.setState({ 
        meetings: allPatientMeetings, 
        loading: false })
  }

  render = () => {
    const now = moment().format('YYYYMMDD');
    let meetingsList = this.state.meetings ? this.state.meetings?.filter(item => parseInt(moment(item.startTime).format('YYYYMMDD')) >= parseInt(now) ) : null;

    // TODO: move logic to backend: 
    // 1. shorten list to nearest 10 future meetings 
    // 2. hide old meetings
    meetingsList = meetingsList && meetingsList?.length && meetingsList.length > 10 ? meetingsList.slice(0,10) : meetingsList;
    
    return (
      <>
        {!this.state.loading && this.state.meetings && this.state.meetings.length === 0 && 
        <Row className="justify-content-center">
          <Col md={6} className="d-flex px-0 px-md-4">
            <Card className="bp-card p-4 text-center">
              <p className="m-0">{this.intl.formatMessage({ id: "meeting_not_found" })}</p>
            </Card>
          </Col>
        </Row>}
        <Row>
        {this.state.loading ? <div className="loading-spinner"></div> 
        : meetingsList && meetingsList.map((meeting) => {

          const sessionDateComparison = moment(meeting.startTime).format('YYYYMMDD');
          const sessionDate = moment(meeting.startTime).format(this.intl.formatMessage({ id: "language_date_format" }));
          const startTime = moment(meeting.startTime).format('HH:mm');
          const endDate = moment(meeting.endTime).format('HH:mm');

          return (
            <Col md={6} className={`d-flex px-0 px-md-4`} key={meeting.meetingId+meeting.startTime}>
              <Card className={`bp-card mb-2 mb-md-5 ${now !== sessionDateComparison ? ' default-cursor' : ''}`} >
                  <Card.Body>
                      <Row>
                          <Col xs={12} className="d-flex justify-content-center">
                              <h5 className="font-weight-bold mb-0">{meeting.subject}</h5>
                          </Col>
                      </Row>
                      <Row>
                          <Col xs={12} className="d-flex justify-content-center">
                              <p className="mb-0 mt-1">{`${sessionDate} ${startTime}-${endDate}`}</p>
                          </Col>
                      </Row>
                      <Row>
                          <Col xs={12} className="d-flex justify-content-center">
                              <button
                                className="register-button mt-3"
                                onClick={() => { return this.goToMeeting(meeting.joinUrl, meeting?.meetingId, meeting?.subject, meeting.body) }}
                                disabled={now !== sessionDateComparison}
                              >{this.intl.formatMessage({ id: "meeting_go_to_meeting" })}</button>
                          </Col>
                      </Row>
                  </Card.Body>
              </Card>
            </Col>)
        })}
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageInformation: (pageInfo) => dispatch(setPageInformationAction(pageInfo)),
  };
}

export default connect(null, mapDispatchToProps)(withRouter(injectIntl(Meetings)));
