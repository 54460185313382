import { createDirectLine } from "botframework-webchat";
import { botflows_scenario } from "./botflows";
import { environment } from "./environment";
import { getBotLocale } from "./lang/localization";
import reduxStore from "./redux/reduxStore";

const fetchToken = async (endpoint) => {
  const response = await fetch(endpoint);
  const responseText = await response.text();
  const { connectorToken: token, userId: id, userName: name } = JSON.parse(
    atob(responseText.split(".")[1])
  );
  return [token, { id, name }];
};

export const initializeDirectLine = async () => {
  const [token, user] = await fetchToken(`${environment.botApiUrl}/GenereratorFunction`);
  const directLine = createDirectLine({ token });
  return [directLine, user];
};

export const callActivity = async (directLine, locale, id, user, onActivityUpdate, action) => {
  const state = reduxStore.getState();

  if (action === undefined || !directLine) return;

  let addCarePlan = sessionStorage.getItem('addCarePlan');
  let careunit = sessionStorage.getItem('careunit');
  sessionStorage.removeItem('careunit');

  if(addCarePlan && addCarePlan !== 'meeting'){
    const replaced = addCarePlan.substring(0, 0) + addCarePlan.charAt(0).toUpperCase() + addCarePlan.substring(0 + 1);
    addCarePlan = replaced;
  }

  await directLine
    .postActivity({
      type: "invoke",
      value: {
        trigger: action.scenario,
        args: {
          source: window.location.href,
          "token": state.userInfo.token,
          id,
          ...(action.scenario === botflows_scenario["article"] && {
            "nbrOfArticles": 20,
          }),
          "localTime" : new Date().getHours().toString().padStart(2,'0') + ':' + new Date().getMinutes().toString().padStart(2,'0'),
          ...((action.card) && { card: action.card }),
          ...((action.page) && { page: action.page }),
          "locale": getBotLocale(locale),          
          ...((addCarePlan) && { addcareplan: addCarePlan }),
          ...((action.isRedirect) && { isRedirect: action.isRedirect }),
          observationCode: state.pageInfo.observationCode || "",
          careunit: careunit ? careunit : undefined
        },
      },
      locale: getBotLocale(locale),
      from: user,
      name: "TriggerScenario",
    })
    .subscribe(
      id => {
        console.log("Posted activity, assigned ID ", id)
        sessionStorage.removeItem('addCarePlan');
      },
      error => console.log("Error posting activity", error));
  directLine.activity$
    .filter(
      ({ type, entities }) => {
        return type === "message" && Array.isArray(entities) && entities.length > 0
      }
    )
    .subscribe(({ entities, value }) => {
      if (value) {
        return onActivityUpdate(value)
      }
      if (entities.length > 0) {
        onActivityUpdate(entities);
      }
    });
};
