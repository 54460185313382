import React, { useEffect } from "react";
import Layout from "../components/Shared/Layout";
import {SecureRoute, useOktaAuth} from '@okta/okta-react';
import {connect} from "react-redux";
import {setUserInformationAction} from "../redux/actions";

export const ProtectedRoute = ({component: Component, ...rest}) => {
  const {authState} = useOktaAuth();

  useEffect(() => {
    if (authState.isAuthenticated) {
      const userInfo = {
        userName: authState.accessToken.claims.sub,
        patientId: authState.accessToken.claims.uid,
        token: authState.idToken.idToken
      };
      
      rest.setUserInformation(userInfo);
    }
  }, [authState.isAuthenticated, authState?.idToken?.idToken])

  return (
    <SecureRoute
      {...rest}
      render={(props) => (
        <Layout path={rest.path}>
          <Component {...props} {...rest} />
        </Layout>
      )}/>
  );
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserInformation: (userInfo) => dispatch(setUserInformationAction(userInfo))
  }
}
export default connect(null, mapDispatchtoProps)(ProtectedRoute);
