import React from "react";
import ReactWebChat from "botframework-webchat";
import { callActivity } from "../setupHealthBot";
import { Row, Col } from "react-bootstrap";
import { withOktaAuth } from "@okta/okta-react";
import { connect } from "react-redux";
import { getBotLocale } from "../lang/localization";

export class Botflow extends React.Component {
  resizeObserver = new ResizeObserver(() => window.scrollTo(0, document.body.scrollHeight));

  constructor(props) {
    super(props);
    this.state = { observationCode: "" }
  }

  callActivity = () => {
    if (this.props.directLine) {
      (async () => {
        await callActivity(
          this.props.directLine,
          this.props.locale,
          this.props.authState.accessToken.claims.uid,
          this.props.user,
          this.props.onActivityUpdate,
          this.props.pageInfo.botTriggerAction,
          this.props.userInfo.token,
        );
      })();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.directLine && this.props.directLine) {
      this.callActivity();
    }
  }

  componentDidMount() {
    this.callActivity();
    this.resizeObserver.observe(document.body)
  }

  componentWillUnmount() {
    this.props.disconnectDirectLine();
    this.resizeObserver.disconnect();
  }

  render = () => {
    return (
      <Row>
        <Col className="px-0 px-md-4">
          {this.props.directLine && this.props.stateStore && this.props.user.name && (
            <ReactWebChat
              directLine={this.props.directLine}
              store={this.props.stateStore}
              userID={this.props.user.id}
              username={this.props.user.name}
              locale={getBotLocale(this.props.locale)}
              botAvatarInitials="capio"
              userAvatarInitials={this.props.user.name.substr(0, 3)}
              styleOptions={{
                hideUploadButton: true,
                bubbleTextColor: "#0d3050",
                emojiSet: true || false || { ':)': '😊' },
                rootWidth: '100%',                
                hideSendBox: this.props.pageInfo.hideSendBox ? true : false,
                bubbleMaxWidth: '100%'
              }}
            />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  if (!state.pageInfo.botTriggerAction) {
    const action = localStorage.getItem("botTriggerAction")?.split("#");
    const info = {
      pageHeader: localStorage.getItem("pageHeader"),
      botTriggerAction: {
        scenario: action ? action[0] : "",
        card: action ? action[1] : "",
      },
      previousPath: localStorage.getItem("previousPath")
    }
    return {
      pageInfo: info,
      userInfo: state.userInfo
    };
  }
  return {
    pageInfo: state.pageInfo,
    userInfo: state.userInfo
  };
}

export default connect(mapStateToProps)(withOktaAuth(Botflow));
